import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class ParticipationInnovation extends Component {
    state = {
        description: '',
        api_score: '',
       participation_innovation: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            participation_innovation:this.props.participation_innovation,
            
        })
    }

    componentDidUpdate(prevProps){
        //console.log(this.props)

        if(this.props !== prevProps){
            this.setState({
                participation_innovation:this.props.participation_innovation,
              

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            description: this.state.description,
            api_score: this.state.api_score,
            
        }

        let participation_innovation = this.state.participation_innovation;
        participation_innovation.push(d);
        this.setState({
            participation_innovation
        }, () => {
                this.props.setParticipationInnovation(participation_innovation);
                this.setState({
                    description: '',
                    api_score: '',
                })
        })
    }

    onDelete = (index) => {
        let participation_innovation = this.state.participation_innovation;
        participation_innovation.splice(index,1);
        this.props.setParticipationInnovation(participation_innovation);
        this.setState({
            participation_innovation
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr >
                            <td >Short Description</td>
                            <td >API Score</td>
                           
                            
                            <td >Click + to Add</td>
                        </tr>
                       
                    </thead>
                    <tbody>
                        <tr>
                            <td width="80%">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.onChange}
                                    placeholder="Short Description"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="api_score"
                                    value={this.state.api_score}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>
                            

                           
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.participation_innovation.map((el, index) =>

                            <tr key={index}>
                                <td >{index+1}. {el.description}</td>
                                <td>{el.api_score}</td>
                                
                                
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}

                        {this.state.participation_innovation.length>0 && <tr>
                            <td colSpan={2}>Total Score</td>
                            <td>{this.state.participation_innovation.reduce((pre,curr)=>parseFloat(pre)+parseFloat(curr.api_score),0)}</td>
                            </tr>
                            }
                         
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(ParticipationInnovation)
import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';
import PreviousExamination from './PreviousExamination';

import { blue } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileUpload from './FileUpload';

import allStates from './../../../Helper/IndianStates.json'
import { GetData } from '../../../api/service';
import PreviousAppointment from './PreviousAppointment';
import PostAppointment from './PostAppointment';
import OtherCourseAttended from './OtherCourseAttended';
import Activities from './Activities';
import Journals from './Journals';
import Articles from './Articles';
import FullPapers from './FullPapers';
import BooksPublished from './BooksPublished';
import OnGoingProjects from './OnGoingProjects';
import CompletedProjects from './CompletedProjects';
import ResearchGuidance from './ResearchGuidance';
import TrainingCourses from './TrainingCourses';
import PapersPresented from './PapersPresented';
import InvitedLectures from './InvitedLectures';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})



class FormFive extends Component {

    state = {
        research_guidance:[],
        training_courses:[],
        papers_presented:[],
        invited_lectures:[],
        
        phone: this.props.user.phone,
        email: this.props.user.email,
        step:4,
        total:0,
        step5_remarks:''
    }

    setResearchGuidance = (research_guidance) =>{
        //console.log(research_guidance)
        this.setState({
            research_guidance
        },()=>{
            let total = 0;
            research_guidance.forEach((el)=>{
                total +=  parseFloat(el.score)
            });
            //console.log(total);
            this.setState({
                total
            })
        })
    }

    setTrainingCourses = (training_courses) =>{
        //console.log(training_courses)
        this.setState({
            training_courses
        })
    }

    setPapersPresented = (papers_presented) =>{
        //console.log(papers_presented)
        this.setState({
            papers_presented
        })
    }
    setInvitedLectures = (invited_lectures) =>{
        //console.log(invited_lectures)
        this.setState({
            invited_lectures
        })
    }


    componentDidMount() {


        let data = this.props.data;
        //console.log(data)
       
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
                total:data[0].research_total
            })
        }

    }

  


    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0],
                    total:data[0].research_total

                })
            }
        }

    }

    

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

       
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.setData(this.state);
       

    }

   
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div style={{ borderLeft: 'solid 5px #1715DA',paddingLeft:'10px' }}>
                    <Typography
                        variant="h5"
                    >
                        Application Form
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>

                    </div>

                   

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>

                        <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                              CATEGORY III: RESEARCH, PUBLICATIONS AND ACADEMIC CONTRIBUTIONS 
                            </Typography>
                            <br />



                            <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            D) Research Guidance
                            </Typography>
                                <ResearchGuidance setResearchGuidance={this.setResearchGuidance} research_guidance={this.state.research_guidance} total={this.state.total} />
                                </Grid>

                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            E) (i) Training Courses, Teaching-Learning-Evaluation Technology Programmes, Faculty Development Programmes (Not less than one week duration)
                            </Typography>
                                <TrainingCourses setTrainingCourses={this.setTrainingCourses}  training_courses={this.state.training_courses} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            E) ii) Papers presented in Conferences, Seminars, Workshop, Symposia
                            </Typography>
                                <PapersPresented setPapersPresented={this.setPapersPresented}  papers_presented={this.state.papers_presented} />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                          E) (iii) Invited Lectures and Chairmanships at national or international conference/seminar etc.
                            </Typography>
                                <InvitedLectures setInvitedLectures={this.setInvitedLectures}  invited_lectures={this.state.invited_lectures} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                         
                          Remarks (if any)
                            </Typography>
                            <textarea
                                    className="form-control"
                                    type="text"
                                    name="step5_remarks"
                                    value={this.state.step5_remarks}
                                    onChange={this.onChange}
                                    placeholder="Remarks"
                                />
                                </Grid>

                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>
                        <Button

                                    type="button"
                                    onClick={this.props.back}
                                    >
                                    Previous
                                    </Button>
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormFive)
import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class ExaminationDuty extends Component {
    state = {
        type: '',
        duty:'',
        extend:'',
        api_score: '',
       examination_duty: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            examination_duty:this.props.examination_duty,
            
        })
    }

    componentDidUpdate(prevProps){
        //console.log(this.props)

        if(this.props !== prevProps){
            this.setState({
                examination_duty:this.props.examination_duty,
              

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            type: this.state.type,
            duty: this.state.duty,
            extend: this.state.extend,
            api_score: this.state.api_score,
            
        }

        let examination_duty = this.state.examination_duty;
        examination_duty.push(d);
        this.setState({
            examination_duty
        }, () => {
                this.props.setExaminationDuty(examination_duty);
                this.setState({
                    type: '',
                    duty:'',
                    extend:'',
                    api_score: '',
                })
        })
    }

    onDelete = (index) => {
        let examination_duty = this.state.examination_duty;
        examination_duty.splice(index,1);
        this.props.setExaminationDuty(examination_duty);
        this.setState({
            examination_duty
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr >
                            <td >Type of Examination Duties</td>
                            <td>Duties Assigned</td>
                           <td>Extend to which carried out (%)</td>
                           <td >API Score</td>
                            
                            <td >Click + to Add</td>
                        </tr>
                       
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="type"
                                    value={this.state.type}
                                    onChange={this.onChange}
                                    placeholder="Type of Examination Duties"
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="duty"
                                    value={this.state.duty}
                                    onChange={this.onChange}
                                    placeholder="Duties Assigned"
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="type"
                                    name="extend"
                                    value={this.state.extend}
                                    onChange={this.onChange}
                                    placeholder="Extend to which carried out (%)"
                                />
                            </td>


                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="api_score"
                                    value={this.state.api_score}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>
                            

                           
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.examination_duty.map((el, index) =>

                            <tr key={index}>
                                <td >{index+1}. {el.type}</td>
                                <td>{el.duty}</td>
                                <td>{el.extend}</td>
                                <td>{el.api_score}</td>
                                
                                
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}

                        {this.state.examination_duty.length>0 && <tr>
                            <td colSpan={4}>Total Score</td>
                            <td>{this.state.examination_duty.reduce((pre,curr)=>parseFloat(pre)+parseFloat(curr.api_score),0)}</td>
                            </tr>
                            }
                         
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(ExaminationDuty)
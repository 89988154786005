import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class OtherCourseAttended extends Component {
    state = {
        course: '',
        place: '',
        duration: '',
        sponsor: '',
       
        other_course_attended: []
    }

    componentDidMount(){
        this.setState({
            other_course_attended:this.props.other_course_attended
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                other_course_attended:this.props.other_course_attended

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            course: this.state.course,
            place: this.state.place,
            duration: this.state.duration,
            sponsor: this.state.sponsor,

        }

        let other_course_attended = this.state.other_course_attended;
        other_course_attended.push(d);
        this.setState({
            other_course_attended
        }, () => {
                this.props.setOtherCourse(other_course_attended);
                this.setState({
                    course: '',
                    place: '',
                    duration: '',
                    sponsor: '',
                })
        })
    }

    onDelete = (index) => {
        let other_course_attended = this.state.other_course_attended;
        other_course_attended.splice(index,1);
        this.props.setOtherCourse(other_course_attended);

        this.setState({
            other_course_attended
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td>Name of Course</td>
                            <td>Place</td>
                            <td>Duration</td>

                            <td>Sponsoring Agency</td>
                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="course"
                                    value={this.state.course}
                                    onChange={this.onChange}
                                    placeholder="Name of Course"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="place"
                                    value={this.state.place}
                                    onChange={this.onChange}
                                    placeholder="Place"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="duration"
                                    value={this.state.duration}
                                    onChange={this.onChange}
                                    placeholder="Duration"
                                />
                            </td>
                            
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="sponsor"
                                    value={this.state.sponsor}
                                    onChange={this.onChange}
                                    placeholder="Sponsoring Agency"
                                />
                            </td>
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.other_course_attended.map((el, index) =>

                            <tr key={index}>
                                <td>{el.course}</td>
                                <td>{el.place}</td>
                                <td>{el.duration}</td>
                                <td>{el.sponsor}</td>
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(OtherCourseAttended)
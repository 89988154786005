import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import FormsLanding from './Forms/FormsLanding';

const styles = theme => ({

})

class ProfileLanding extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <FormsLanding
                     history={this.props.history}
                     username={this.props.username}
                     name={this.props.name}
                     user={this.props.user}
                     apikey={this.props.apikey}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ProfileLanding)
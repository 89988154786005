import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
} from "@material-ui/core";
import PreviousExamination from "./PreviousExamination";

import { blue } from "@material-ui/core/colors";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import FileUpload from "./FileUpload";

import allStates from "./../../../Helper/IndianStates.json";
import { GetData } from "../../../api/service";
import PreviousAppointment from "./PreviousAppointment";
import PostAppointment from "./PostAppointment";
import OtherCourseAttended from "./OtherCourseAttended";
import Activities from "./Activities";
import Journals from "./Journals";
import JournalPaper from "./JournalPaper";

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});

class FormThree extends Component {
  state = {
    journals: [],
    journal_paper: [],
    phone: this.props.user.phone,
    email: this.props.user.email,
    step: 2,
    total: 0,
  };

  setJournals = (journals) => {
    //console.log(journals)
    this.setState(
      {
        journals,
      },
      () => {
        let total = 0;
        journals.forEach((el) => {
          total += parseFloat(el.api_score);
        });
        //console.log(total);
        this.setState({
          total,
        });
      }
    );
  };

  setJournalPaper = (journal_paper) => {
    this.setState({
      journal_paper,
    });
  };

  componentDidMount() {
    let data = this.props.data;
    //console.log(data)

    if (Array.isArray(data) && data.length > 0) {
      this.setState({
        ...data[0],
        total: data[0].journal_total,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      let data = this.props.data;
      if (Array.isArray(data) && data.length > 0) {
        this.setState({
          ...data[0],
          total: data[0].journal_total,
        });
      }
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.setData(this.state);
  };

  render() {
    let { classes } = this.props;
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <div style={{ borderLeft: "solid 5px #1715DA", paddingLeft: "10px" }}>
            <Typography variant="h5">Application Form</Typography>
            <Typography>
              All <span className="red-alert">*</span> marked fields are
              mandatory
            </Typography>
            {!!this.state.step3_remarks && (
              <Typography>
                <span className="red-alert">{this.state.step3_remarks}</span>
              </Typography>
            )}
          </div>

          <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  color: blue[700],
                }}
              >
                CATEGORY III: RESEARCH, PUBLICATIONS AND ACADEMIC CONTRIBUTIONS
              </Typography>
              <br />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Typography
                    variant="h5"
                    style={{
                      color: blue[700],
                    }}
                  >
                    A) (i) Published Papers in Journals
                  </Typography>
                  <Journals
                    setJournals={this.setJournals}
                    total={this.state.total}
                    journals={this.state.journals}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Typography
                    variant="h5"
                    style={{
                      color: blue[700],
                    }}
                  >
                    ii) Published Paper in Journals notified on UGC website :
                  </Typography>
                  <JournalPaper
                    setJournalPaper={this.setJournalPaper}
                    journal_paper={this.state.journal_paper}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions className={classes.cardActions}>
              <Button type="button" onClick={this.props.back}>
                Previous
              </Button>
              <Button
                variant="contained"
                className={classes.buttonSubmit}
                type="submit"
              >
                Save &amp; Proceed to next step
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(FormThree);

import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { ReactSortable } from "react-sortablejs";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "scroll",
  },
});

class Journals extends Component {
  state = {
    title: "",
    journal: "",
    issn: "",
    impact_factor: "",
    no_co_au: "",
    main_au: "",
    api_score: "",
    total: "",
    year: "",
    journals: [],
  };

  componentDidMount() {
    //console.log(this.props)
    this.setState({
      journals: this.props.journals,
      total: this.props.total,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        journals: this.props.journals,
        total: this.props.total,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeNew = (name, value, index, array) => {
    let data = this.state[array];
    data[index][name] = value;

    this.setState({
      [array]: data,
    });
  };

  onSubmit = () => {
    let d = {
      title: this.state.title,
      journal: this.state.journal,
      issn: this.state.issn,
      impact_factor: this.state.impact_factor,
      no_co_au: this.state.no_co_au,
      main_au: this.state.main_au,
      api_score: this.state.api_score,
      year: this.state.year,
    };

    let journals = this.state.journals;
    journals.push(d);
    this.setState(
      {
        journals,
      },
      () => {
        this.props.setJournals(journals);
        this.setState({
          title: "",
          journal: "",
          issn: "",
          impact_factor: "",
          no_co_au: "",
          main_au: "",
          api_score: "",
          year: "",
        });
      }
    );
  };

  onDelete = (index) => {
    let journals = this.state.journals;
    journals.splice(index, 1);
    this.props.setJournals(journals);
    this.setState({
      journals,
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <table width="100%" className="table">
          <thead>
            <tr>
              <td width="30%">Title with Page Numbers</td>
              <td width="20%">Journal</td>

              <td width="8%">ISSN No.</td>
              <td width="8%">Whether Peer Reviewed Impact Factor, if any </td>
              <td width="8%">No. of Co-author</td>
              <td width="8%">Whether you are the main author</td>
              <td width="5%">API Score</td>
              <td width="10%">Year</td>
              <td width="5%">Click + to Add</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="30%">
                <textarea
                  className="form-control"
                  type="text"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  placeholder="Title with Page Numbers"
                />
              </td>
              <td width="20%">
                <textarea
                  className="form-control"
                  type="text"
                  name="journal"
                  value={this.state.journal}
                  onChange={this.onChange}
                  placeholder="Journal"
                />
              </td>
              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="issn"
                  value={this.state.issn}
                  onChange={this.onChange}
                  placeholder="ISSN/ ISBN No"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="impact_factor"
                  value={this.state.impact_factor}
                  onChange={this.onChange}
                  placeholder="Whether Peer Reviewed Impact Factor, if any"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="no_co_au"
                  value={this.state.no_co_au}
                  onChange={this.onChange}
                  placeholder="No. of 
                                    Co-author"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="main_au"
                  value={this.state.main_au}
                  onChange={this.onChange}
                  placeholder="Whether you are the main author"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="api_score"
                  value={this.state.api_score}
                  onChange={this.onChange}
                  placeholder="API Score"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="year"
                  value={this.state.year}
                  onChange={this.onChange}
                  placeholder="Year "
                />
              </td>

              <td>
                <Button
                  variant="outlined"
                  onClick={this.onSubmit}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {this.state.journals.map((el, index) => (
              <tr key={index}>
                <td width="30%">
                  
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="title"
                    value={el.title}
                    onChange={(e) =>
                      this.onChangeNew(
                        "title",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="Title with Page Numbers"
                  />
                </td>
                <td width="18%">
                <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="title"
                    value={el.journal}
                    onChange={(e) =>
                      this.onChangeNew(
                        "journal",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="Journal"
                  /></td>
                <td width="8%">
                
                <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="issn"
                    value={el.issn}
                    onChange={(e) =>
                      this.onChangeNew(
                        "issn",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="ISSN"
                  /></td>


                <td width="10%">
                <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="issn"
                    value={el.impact_factor}
                    onChange={(e) =>
                      this.onChangeNew(
                        "impact_factor",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="Whether Peer Reviewed Impact Factor, if any"
                  />
                  
                  </td>
                <td width="10%">
                <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="no_co_au"
                    value={el.no_co_au}
                    onChange={(e) =>
                      this.onChangeNew(
                        "no_co_au",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="No. of Co-author	"
                  />
                  
                  </td>
                <td width="10%">
                <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="main_au"
                    value={el.main_au}
                    onChange={(e) =>
                      this.onChangeNew(
                        "main_au",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="Whether you are the main author	"
                  /></td>


                <td width="5%">
                <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="api_score"
                    value={el.api_score}
                    onChange={(e) =>
                      this.onChangeNew(
                        "api_score",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="API Score	"
                  /></td>


                <td width="5%">
                <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="year"
                    value={el.year}
                    onChange={(e) =>
                      this.onChangeNew(
                        "year",
                        e.target.value,
                        index,
                        "journals"
                      )
                    }
                    placeholder="Year"
                  />
                </td>

                <td width="10%">
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}

            <tr>
              <td colSpan={9}>&nbsp;</td>
            </tr>
            {this.state.journals.length > 0 && (
              <tr>
                <td colSpan={6} align="right">
                  Total
                </td>
                <td colSpan={3} align="left">
                  {this.state.total}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(styles)(Journals);

import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class CompletedProjects extends Component {
    state = {
        title: '',
        agency: '',
        period: '',
        grant:'',
        document:'',
        api_score:'',
        completed_projects: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            completed_projects:this.props.completed_projects,
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                completed_projects:this.props.completed_projects,

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            title: this.state.title,
            agency: this.state.agency,
            period: this.state.period,
            grant: this.state.grant,
            document: this.state.document,
            api_score: this.state.api_score,
            
        }

        let completed_projects = this.state.completed_projects;
        completed_projects.push(d);
        this.setState({
            completed_projects
        }, () => {
                this.props.setCompletedProjects(completed_projects);
                this.setState({
                    title: '',
                    agency: '',
                    period: '',
                    grant:'',
                    document:'',
                    api_score:'',
                })
        })
    }

    onDelete = (index) => {
        let completed_projects = this.state.completed_projects;
        completed_projects.splice(index,1);
        this.props.setCompletedProjects(completed_projects);
        this.setState({
            completed_projects
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td width="30%">Title</td>
                            <td width="20%">Agency</td>
                           
                            <td>Period</td>
                            <td>Grant/ Amount Mobilized (Rs. Lakh)</td>
                            <td>Whether policy document/ patent as outcome</td>
                           
                                    <td>API Score</td>
                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="30%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="title"
                                    value={this.state.title}
                                    onChange={this.onChange}
                                    placeholder="Title"
                                />
                            </td>
                            <td width="20%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="agency"
                                    value={this.state.agency}
                                    onChange={this.onChange}
                                    placeholder="Agency"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="period"
                                    value={this.state.period}
                                    onChange={this.onChange}
                                    placeholder="Period"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="grant"
                                    value={this.state.grant}
                                    onChange={this.onChange}
                                    placeholder="Grant/ Amount Mobilized (Rs. Lakh)"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="document"
                                    value={this.state.document}
                                    onChange={this.onChange}
                                    placeholder="Whether policy document/ patent as outcome"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="api_score"
                                    value={this.state.api_score}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.completed_projects.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.title}</td>
                                <td>{el.agency}</td>
                                <td>{el.period}</td>
                                <td>{el.grant}</td>
                                <td>{el.document}</td>
                                <td>{el.api_score}</td>
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(CompletedProjects)
import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { ReactSortable } from "react-sortablejs";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "scroll",
  },
});

class JournalPaper extends Component {
  state = {
    title: "",
    journal: "",
    year: "",
    issn: "",
    website_link: "",
    article_link: "",
    listed: "",
    listed_main: "",
    journal_paper: [],
  };

  componentDidMount() {
    //console.log(this.props)
    this.setState({
      journal_paper: this.props.journal_paper,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        journal_paper: this.props.journal_paper,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeNew = (name, value, index, array) => {
    let data = this.state[array];
    data[index][name] = value;

    this.setState({
      [array]: data,
    });
  };

  onSubmit = () => {
    let d = {
      title: this.state.title,
      journal: this.state.journal,
      issn: this.state.issn,
      year: this.state.year,
      website_link: this.state.website_link,
      article_link: this.state.article_link,
      listed: this.state.listed,
      listed_main: this.state.listed_main,
    };

    let journal_paper = this.state.journal_paper;
    journal_paper.push(d);
    this.setState(
      {
        journal_paper,
      },
      () => {
        this.props.setJournalPaper(journal_paper);
        this.setState({
          title: "",
          journal: "",
          year: "",
          issn: "",
          website_link: "",
          article_link: "",
          listed: "",
          listed_main: "",
        });
      }
    );
  };

  onDelete = (index) => {
    let journal_paper = this.state.journal_paper;
    journal_paper.splice(index, 1);
    this.props.setJournalPaper(journal_paper);
    this.setState({
      journal_paper,
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <table width="100%" className="table">
          <thead>
            <tr>
              <td width="20%" rowSpan={2}>
                Title of the paper
              </td>
              <td width="10%" rowSpan={2}>
                Name of the journal
              </td>

              <td width="10%" rowSpan={2}>
                Year of Publication.
              </td>
              <td width="5%" rowSpan={2}>
                ISSN No.{" "}
              </td>
              <td width="45%" colSpan={3}>
                Link to the recognition in UGC enlistment of the Journal/
                Digital Object Identifier (doi) No.
              </td>

              <td width="10%" rowSpan={2}>
                Click + to Add
              </td>
            </tr>
            <tr>
              <td width="5%">Link to Website of the Journal</td>
              <td width="5%">
                Link to article/ paper/ abstract of the article
              </td>
              <td style={{ width: "50%" }}>
                Is it listed in UGC Carelist /Scopus/ Web of Science / Other,
                Mention
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  placeholder="Title of the paper"
                />
              </td>
              <td width="20%">
                <textarea
                  className="form-control"
                  type="text"
                  name="journal"
                  value={this.state.journal}
                  onChange={this.onChange}
                  placeholder="Name of the journal"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="year"
                  value={this.state.year}
                  onChange={this.onChange}
                  placeholder="Year of Publication"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="issn"
                  value={this.state.issn}
                  onChange={this.onChange}
                  placeholder="ISSN No."
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="website_link"
                  value={this.state.website_link}
                  onChange={this.onChange}
                  placeholder="Link to Website of the Journal"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="article_link"
                  value={this.state.article_link}
                  onChange={this.onChange}
                  placeholder="Link to article/ paper/ abstract of the article"
                />
              </td>

              <td>
                <select
                  style={{ width: "200px" }}
                  className="form-control"
                  value={this.state.listed_main}
                  name="listed_main"
                  onChange={this.onChange}
                >
                  <option value="">--Select--</option>
                  <option value="UGC Carelist">UGC Carelist</option>
                  <option value="Scopus">Scopus</option>
                  <option value="Web of Science">Web of Science</option>
                  <option value="Other">Other</option>
                </select>

                <input
                  className="form-control"
                  type="text"
                  style={{ width: "200px" }}
                  name="listed"
                  value={this.state.listed}
                  onChange={this.onChange}
                  placeholder="Mention other"
                />
              </td>

              <td>
                <Button
                  variant="outlined"
                  onClick={this.onSubmit}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {this.state.journal_paper.map((el, index) => (
              <tr>
                <td
                  width="20%"
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="title"
                    value={el.title}
                    onChange={(e) =>
                      this.onChangeNew(
                        "title",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                    placeholder="Title of the paper	"
                  />
                </td>
                <td
                  width="10%"
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  

                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "100%"}}
                    name="journal"
                    value={el.journal}
                    onChange={(e) =>
                      this.onChangeNew(
                        "journal",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                    placeholder="Name of the journal"
                  />
                </td>
                <td
                  width="10%"
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  

                  <input
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "70px"}}
                    name="year"
                    value={el.year}
                    onChange={(e) =>
                      this.onChangeNew(
                        "year",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                    placeholder="Year"
                  />
                </td>
                <td
                  width="5%"
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                 

                  <input
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "70px"}}
                    name="issn"
                    value={el.issn}
                    onChange={(e) =>
                      this.onChangeNew(
                        "issn",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                    placeholder="ISSN No.	"
                  />
                </td>
                <td
                  width="15%"
                  style={{
                    width: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  

                  <input
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "150px"}}
                    name="website_link"
                    value={el.website_link}
                    onChange={(e) =>
                      this.onChangeNew(
                        "website_link",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                    placeholder="Link to Website of the Journal	"
                  />
                </td>
                <td
                  width="15%"
                  style={{
                    width: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  
                  <input
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{width: "150px"}}
                    name="article_link"
                    value={el.article_link}
                    onChange={(e) =>
                      this.onChangeNew(
                        "article_link",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                    placeholder="Link to article/ paper/ abstract of the article"
                  />
                </td>
                <td
                  width="15%"
                  style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                >
                  

                  <select
                  style={{ width: "200px" }}
                  className="form-control"
                  value={el.listed_main}
                  name="listed_main"
                  onChange={(e) =>
                      this.onChangeNew(
                        "listed_main",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                >
                  <option value="">--Select--</option>
                  <option value="UGC Carelist">UGC Carelist</option>
                  <option value="Scopus">Scopus</option>
                  <option value="Web of Science">Web of Science</option>
                  <option value="Other">Other</option>
                </select>

                <input
                  className="form-control"
                  type="text"
                  style={{ width: "200px" }}
                  name="listed"
                  value={el.listed}
                  onChange={(e) =>
                      this.onChangeNew(
                        "listed",
                        e.target.value,
                        index,
                        "journal_paper"
                      )
                    }
                  placeholder="Mention other"
                />
                </td>
                <td width="10%">
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(styles)(JournalPaper);

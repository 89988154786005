import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class PreviousExamination extends Component {
    state = {
        examination_passed: '',
        board: '',
        year: '',
        division:'',
        subjects: '',
        last_exam: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            last_exam:this.props.last_exam
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                last_exam:this.props.last_exam
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            examination_passed: this.state.examination_passed,
            board: this.state.board,
            year: this.state.year,
            division: this.state.division,
            subjects: this.state.subjects
        }

        let last_exam = this.state.last_exam;
        last_exam.push(d);
        this.setState({
            last_exam
        }, () => {
                this.props.setLastExam(last_exam);
                this.setState({
                    examination_passed: '',
                    board: '',
                    year: '',
                    division:'',
                    subjects: '',
                })
        })
    }

    onDelete = (index) => {
        let last_exam = this.state.last_exam;
        last_exam.splice(index,1);
        this.props.setLastExam(last_exam);
        this.setState({
            last_exam
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td>Examination Passed</td>
                            <td>Board / Counciil / University</td>
                            <td>Year of Passing</td>
                            <td>Division / Class / Grade</td>

                            <td>Subject</td>
                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="examination_passed"
                                    value={this.state.examination_passed}
                                    onChange={this.onChange}
                                    placeholder="Examination Passed"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="board"
                                    value={this.state.board}
                                    onChange={this.onChange}
                                    placeholder="Board / Counciil / University"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="year"
                                    value={this.state.year}
                                    onChange={this.onChange}
                                    placeholder="Year of Passing"
                                />
                            </td>
                            
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="division"
                                    value={this.state.division}
                                    onChange={this.onChange}
                                    placeholder="Division / Class / Grade"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="subjects"
                                    value={this.state.subjects}
                                    onChange={this.onChange}
                                    placeholder="Subjects"
                                />
                            </td>
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.last_exam.map((el, index) =>

                            <tr key={index}>
                                <td>{el.examination_passed}</td>
                                <td>{el.board}</td>
                                <td>{el.year}</td>
                                <td>{el.division}</td>
                                <td>{el.subjects}</td>
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(PreviousExamination)
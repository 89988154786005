import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class Activities extends Component {
    state = {
        activity_name: '',
        period: '',
        api_claimed: '',
        total:0,
        activities: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            activities:this.props.activities,
            total:this.props.total
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                activities:this.props.activities,
                total:this.props.total

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            activity_name: this.state.activity_name,
            period: this.state.period,
            api_claimed: this.state.api_claimed,
            
        }

        let activities = this.state.activities;
        activities.push(d);
        this.setState({
            activities
        }, () => {
                this.props.setActivities(activities);
                this.setState({
                    activity_name: '',
                    period: '',
                    api_claimed: '',
                })
        })
    }

    onDelete = (index) => {
        let activities = this.state.activities;
        activities.splice(index,1);
        this.props.setActivities(activities);
        this.setState({
            activities
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td width="70%">Activity Name</td>
                            <td>Period</td>
                            <td>API claimed</td>
                            
                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="70%">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="activity_name"
                                    value={this.state.activity_name}
                                    onChange={this.onChange}
                                    placeholder="Extension, Co-curricular & Field Based activities"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="period"
                                    value={this.state.period}
                                    onChange={this.onChange}
                                    placeholder="Period"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="api_claimed"
                                    value={this.state.api_claimed}
                                    onChange={this.onChange}
                                    placeholder="API claimed"
                                />
                            </td>
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.activities.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.activity_name}</td>
                                <td>{el.period}</td>
                                <td>{el.api_claimed}</td>
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                        <tr><td colSpan={4}>&nbsp;</td></tr>
                        {this.state.activities.length>0 && <tr>
                            <td colSpan={2} align="right">Total</td>
                            <td colSpan={2} align="left">{this.state.total}</td>
                            </tr>}
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(Activities)
import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class Articles extends Component {
    state = {
        title: '',
        publisher: '',
        issn: '',
        impact_factor:'',
        no_co_au:'',
        main_au:'',
        institution:'',
        api_score:'',
        year:'',
        articles: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            articles:this.props.articles,
            
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                articles:this.props.articles,
                
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            title: this.state.title,
            publisher: this.state.publisher,
            issn: this.state.issn,
            impact_factor: this.state.impact_factor,
            no_co_au: this.state.no_co_au,
            main_au: this.state.main_au,
            institution: this.state.institution,
            api_score: this.state.api_score,
            year: this.state.year,
            
        }

        let articles = this.state.articles;
        articles.push(d);
        this.setState({
            articles
        }, () => {
                this.props.setArticles(articles);
                this.setState({
                    title: '',
                    publisher: '',
                    issn: '',
                    impact_factor:'',
                    no_co_au:'',
                    main_au:'',
                    institution:'',
                    api_score:'',
                    year:'',
                })
        })
    }

    onDelete = (index) => {
        let articles = this.state.articles;
        articles.splice(index,1);
        this.props.setArticles(articles);
        this.setState({
            articles
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td width="30%">Title with Page Numbers</td>
                            <td width="20%">Book Title, editor & publisher and Year</td>
                           
                            <td>ISSN/ ISBN No.</td>
                            <td>Whether Peer Reviewed Impact Factor, if any </td>
                            <td>No. of Co-author</td>
                            <td>Whether you are the main author</td>
                            <td>Whether at the time of publication Affiliating Institution was same
                            Yes/ No</td>
                            <td>API Score</td>
                            <td>Year</td>

                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="30%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="title"
                                    value={this.state.title}
                                    onChange={this.onChange}
                                    placeholder="Title with Page Numbers"
                                />
                            </td>
                            <td width="20%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="publisher"
                                    value={this.state.publisher}
                                    onChange={this.onChange}
                                    placeholder="Book Title, Editor & Publisher"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="issn"
                                    value={this.state.issn}
                                    onChange={this.onChange}
                                    placeholder="ISSN/ ISBN No"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="impact_factor"
                                    value={this.state.impact_factor}
                                    onChange={this.onChange}
                                    placeholder="Whether Peer Reviewed Impact Factor, if any"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="no_co_au"
                                    value={this.state.no_co_au}
                                    onChange={this.onChange}
                                    placeholder="No. of 
                                    Co-author"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="main_au"
                                    value={this.state.main_au}
                                    onChange={this.onChange}
                                    placeholder="Whether you are the main author"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="institution"
                                    value={this.state.institution}
                                    onChange={this.onChange}
                                    placeholder="Yes/No"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="api_score"
                                    value={this.state.api_score}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="year"
                                    value={this.state.year}
                                    onChange={this.onChange}
                                    placeholder="Year"
                                />
                            </td>
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.articles.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.title}</td>
                                <td>{el.publisher}</td>
                                <td>{el.issn}</td>
                                <td>{el.impact_factor}</td>
                                <td>{el.no_co_au}</td>
                                <td>{el.main_au}</td>
                                <td>{el.institution}</td>
                                <td>{el.api_score}</td>
                                <td>{el.year}</td>
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                        
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(Articles)
export const APP_NAME = "BH_COLLEGE_TEACHER";
export const APP_GOOD_NAME = "B.H COLLEGE";
export const DEFAULT_COLOR = "#2196F3";
export const DRAWER_WIDTH = 180;

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://backend.bhcollege.ac.in/public' : 'http://localhost/bhcollege/api/public';


export const ACCESS_CONTROL = [
    {
        text: 'ACCOUNTS',
        value: 'ACC'
    },
    {
        text: 'ADMISSION',
        value: 'ADM'
    },
    {
        text: 'FEES COLLECTION',
        value: 'FEE'
    },
    {
        text: 'REPORTS',
        value: 'REP'
    },
    {
        text: 'LEAVE-PRINCIPAL',
        value: 'LEAVEPRINCE'
    }
]


export const COURSE_TYPE_LIST = ["HONOURS","REGULAR"];
export const GENDER_LIST = ["MALE","FEMALE","OTHERS"];
export const CASTE_LIST = ["GENERAL","ST(P)","ST(H)","SC", "OBC"];
export const RELIGION_LIST = ["HINDUISM", "ISLAM", "CHRISTIANISM", "JAINISM", "SIKKHISM"];


export const MERIT_LIST_TYPE = [
    {
        text: 'GENERAL_MERIT_LIST_001',
        key: 'GENERAL_MERIT_LIST_001'
    },
  
    {
        text: 'OBC_MERIT_LIST_001',
        key: 'OBC_MERIT_LIST_001'
    },
    
    {
        text: 'STP_MERIT_LIST_001',
        key: 'STP_MERIT_LIST_001'
    },
    
    {
        text: 'STH_MERIT_LIST_001',
        key: 'STH_MERIT_LIST_001'
    },
   
    {
        text: 'SC_MERIT_LIST_001',
        key: 'SC_MERIT_LIST_001'
    },
    
    {
        text: 'EWS_MERIT_LIST_001',
        key: 'EWS_MERIT_LIST_001'
    },
    {
        text: 'QUOTA_MERIT_LIST_001',
        key: 'QUOTA_MERIT_LIST_001'
    },
    {
        text: 'PWD_MERIT_LIST_001',
        key: 'PWD_MERIT_LIST_001'
    }
]
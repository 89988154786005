import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class InvitedLectures extends Component {
    state = {
        paper: '',
        conference: '',
        orgainised_by: '',
        type:'',
        api_score:'',
       
        invited_lectures: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            invited_lectures:this.props.invited_lectures,
            
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                invited_lectures:this.props.invited_lectures,
              

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            paper: this.state.paper,
            conference: this.state.conference,
            orgainised_by: this.state.orgainised_by,
            type: this.state.type,
            api_score: this.state.api_score,
            
        }

        let invited_lectures = this.state.invited_lectures;
        invited_lectures.push(d);
        this.setState({
            invited_lectures
        }, () => {
                this.props.setInvitedLectures(invited_lectures);
                this.setState({
                    paper: '',
                    conference: '',
                    orgainised_by: '',
                    type:'',
                    api_score:'',
                })
        })
    }

    onDelete = (index) => {
        let invited_lectures = this.state.invited_lectures;
        invited_lectures.splice(index,1);
        this.props.setInvitedLectures(invited_lectures);
        this.setState({
            invited_lectures
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr >
                            <td width="20%">Title of Lecture/ Academic Session</td>
                            <td width="20%">Title of Conference/ Seminar</td>
                           
                            <td >Organized By</td>
                             <td>Whether international/ national/ state/ regional/ college or university</td>           
                           
                            <td >API Score</td>
                                    
                                    
                            <td >Click + to Add</td>
                        </tr>
                       
                    </thead>
                    <tbody>
                        <tr>
                            <td width="20%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="paper"
                                    value={this.state.paper}
                                    onChange={this.onChange}
                                    placeholder="Title of Lecture/ Academic Session"
                                />
                            </td>
                            <td width="20%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="conference"
                                    value={this.state.conference}
                                    onChange={this.onChange}
                                    placeholder="Title of Conference/ Seminar"
                                />
                            </td>
                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="orgainised_by"
                                    value={this.state.orgainised_by}
                                    onChange={this.onChange}
                                    placeholder="Organized By"
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="type"
                                    value={this.state.type}
                                    onChange={this.onChange}
                                    placeholder="Whether international/ national/ state/ regional/ college or university"
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="api_score"
                                    value={this.state.api_score}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>

                           
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.invited_lectures.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.paper}</td>
                                <td>{el.conference}</td>
                                <td>{el.orgainised_by}</td>
                                <td>{el.type}</td>
                                <td>{el.api_score}</td>
                                
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                         
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(InvitedLectures)
import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';
import PreviousExamination from './PreviousExamination';

import { blue } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileUpload from './FileUpload';

import allStates from './../../../Helper/IndianStates.json'
import { GetData } from '../../../api/service';
import PreviousAppointment from './PreviousAppointment';
import PostAppointment from './PostAppointment';
import OtherCourseAttended from './OtherCourseAttended';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})



class FormOne extends Component {

    state = {
        name: this.props.user.name,
        father_name: '',
        dept_code:'',
        department:[],
        h_edu_qua:'',
        c_d_g_p:'',
        date_of_l_p:'',
        date_of_birth: this.props.user.date_of_birth,

        place_of_birth:'',
        gender: '',
        marrital_status: '',
        nationality: 'Indian',
        caste: '',
        present_address: [],
        permanent_address: [],
        research_degree:'',
       
        research_experience:'',
        f_of_specialisation:'',
       
        last_exam:[],
        last_appointment:[],
        post_appointment:[],
        p_of_t_PG:'',
        p_of_t_UG:'',
        other_course_attended:[],

       
        phone: this.props.user.phone,
        email: this.props.user.email,
        step:0,
        
        pre_village: '', pre_po: '', pre_dist: '', pre_state: '', pre_pin: '',
        per_village: '', per_po: '', per_dist: '', per_state: '', per_pin: '',
    }

    setLastExam = (last_exam) =>{
        //console.log(last_exam)
        this.setState({
            last_exam
        })
    }

    setLastAppointment = (last_appointment) =>{
        //console.log(last_appointment)
        this.setState({
            last_appointment
        })
    }

    setPostAppointment = (post_appointment) =>{
        //console.log(post_appointment)
        this.setState({
            post_appointment
        })
    }

    setOtherCourse = (other_course_attended) =>{
        //console.log(other_course_attended)
        this.setState({
            other_course_attended
        })
    }

    componentDidMount() {

        this.getDepartment();

        let data = this.props.data;
        console.log(data)
       
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
                ...data[0].present_address,
                ...data[0].permanent_address,
                
            })
        }

    }

    getDepartment = ()=>{
        GetData(`/getdepartment`)
        .then((resp)=>{
            this.setState({
                department:resp
            })
        })
    }

    toggleAddCh = () => {

        this.setState({
            add_ch: !this.state.add_ch
        }, () => {
            if (this.state.add_ch) {

                this.setState({
                    per_village: this.state.pre_village,
                    per_po: this.state.pre_po,
                    per_dist: this.state.pre_dist,
                    per_state: this.state.pre_state,
                    per_pin: this.state.pre_pin,
                    

                })
            } else {
                this.setState({
                    permanent_address: []
                })
            }
        })
    }

    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0]
                })
            }
        }

    }

    
    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

       
    }

    onSubmit = (e) => {
        e.preventDefault();
        let present_address = {
            pre_village: this.state.pre_village,
            pre_po: this.state.pre_po,
            pre_dist: this.state.pre_dist,
            pre_state: this.state.pre_state,
            pre_pin: this.state.pre_pin,
            
        }
        let permanent_address = {
            per_village: this.state.per_village,
            per_po: this.state.per_po,
            per_dist: this.state.per_dist,
            per_state: this.state.per_state,
            per_pin: this.state.per_pin,
            

        }
        
        this.setState({
            present_address,
            permanent_address,
          
        }, () => { this.props.setData(this.state) })
       

    }

   
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div style={{ borderLeft: 'solid 5px #1715DA',paddingLeft:'10px' }}>
                    <Typography
                        variant="h5"
                    >
                        Application Form
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>
                        {!!this.state.step1_remarks &&  <Typography>
                       <span className="red-alert">{this.state.step1_remarks}</span>
                    </Typography>}
                    </div>

                   

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>

                        <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                PART A: GENERAL INFORMATION AND ACADEMIC BACKGROUND
                            </Typography>
                            <br />


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                     Name (in BLOCK letters) <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        required
                                        
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                     Father's Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="father_name"
                                        value={this.state.father_name}
                                        onChange={this.onChange}
                                        required
                                        
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                       Select Department <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="dept_code"
                                        required
                                        value={this.state.dept_code}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {Array(this.state.department) && this.state.department.length>0 && this.state.department.map((el,index)=>
                                                <option value={el.dept_code} key={index}>{el.dept_name}</option>
                                        )}
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                     Educational Qualification <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="h_edu_qua"
                                        value={this.state.h_edu_qua}
                                        onChange={this.onChange}
                                        required
                                        
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                     Current Designation and Grade Pay <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="c_d_g_p"
                                        value={this.state.c_d_g_p}
                                        onChange={this.onChange}
                                        required
                                        
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Date of Last Promotion 
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="date"
                                        
                                        
                                        name="date_of_l_p"
                                        value={this.state.date_of_l_p}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Date of Birth <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="date"
                                        required
                                        
                                        name="date_of_birth"
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                     Place of Birth <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="place_of_birth"
                                        value={this.state.place_of_birth}
                                        onChange={this.onChange}
                                        required
                                        
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Gender <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="gender"
                                        required
                                        value={this.state.gender}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Marital Status <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="marrital_status"
                                        required
                                        value={this.state.marrital_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Unmarried">Unmarried</option>
                                        <option value="Married">Married</option>
                                    </select>
                                </Grid>

                                

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Nationality <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="nationality"
                                        value={this.state.nationality}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                               


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Caste <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="caste"
                                        required
                                        value={this.state.caste}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="GENERAL">GENERAL</option>
                                        <option value="ST(H)">ST(H)</option>
                                        <option value="ST(P)">ST(P)</option>
                                        <option value="SC">SC</option>
                                        <option value="OBC">OBC/MOBC</option>

                                    </select>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                    

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                               Address for Correspondence (With PIN Code)
                            </Typography>


                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Area / Village / Town<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_village"
                                        value={this.state.pre_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.O<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_po"
                                        value={this.state.pre_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Dist<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_dist"
                                        value={this.state.pre_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State<span className="red-alert">*</span>
                                    </Typography>

                                    <select
                                        className="form-control"
                                        type="text"
                                        name="pre_state"
                                        required
                                        value={this.state.pre_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="pre_pin"
                                        value={this.state.pre_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                               
                            </Grid>
                        </CardContent>
                    </Card>
                   

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                               Permanent Address (With PIN Code)
                                        <span>
                                    <Typography
                                        variant="caption"
                                    >
                                        <input type="checkbox" checked={this.state.add_ch} onClick={this.toggleAddCh} /> same as Present address
                                            </Typography>
                                </span>
                            </Typography>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_village"
                                        value={this.state.per_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.O<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_po"
                                        value={this.state.per_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Dist<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_dist"
                                        value={this.state.per_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State<span className="red-alert">*</span>
                                    </Typography>


                                    <select
                                        className="form-control"
                                        type="text"
                                        name="per_state"
                                        required
                                        value={this.state.per_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="per_pin"
                                        value={this.state.per_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>

                    

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>



                        <CardContent>


                            <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                              Academic Qualification (HSLC till Post Graduation)
                            </Typography>
                                <PreviousExamination setLastExam={this.setLastExam} last_exam={this.state.last_exam} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Research Degree(s)
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="research_degree"
                                        value={this.state.research_degree}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                             Appointment held prior to joining this Institution
                            </Typography>
                                <PreviousAppointment setLastAppointment={this.setLastAppointment} last_appointment={this.state.last_appointment} />
                                </Grid>
                          

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            Post held after appointment at this Institution
                            </Typography>
                                <PostAppointment setPostAppointment={this.setPostAppointment} post_appointment={this.state.post_appointment} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                        Period of Teaching Experience
                                    </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.G. Classes (In Years)
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="p_of_t_PG"
                                        value={this.state.p_of_t_PG}
                                        onChange={this.onChange}
                                    />
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        U.G. Classes (In Years)
                                    </Typography>
                                      
                                        <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="p_of_t_UG"
                                        value={this.state.p_of_t_UG}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                        Research Experience Excluding Years Spent in M.Phil/PhD (In Years)
                                    </Typography>

                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="research_experience"
                                        value={this.state.research_experience}
                                        onChange={this.onChange}
                                    />
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={6} md={6}>
                                <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                        Field of Specialization under the Subject/Discipline (if any):
                                    </Typography>

                                    <input
                                        className="form-control"
                                        type="text"
                                        
                                        name="f_of_specialisation"
                                        value={this.state.f_of_specialisation}
                                        onChange={this.onChange}
                                    />
                                    </Grid>


                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            Academic Staff College Orientation/ Refresher Course/ Summer School/ Any Other Course Attended
                            </Typography>
                                <OtherCourseAttended setOtherCourse={this.setOtherCourse}  other_course_attended={this.state.other_course_attended} />
                                </Grid>

                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>

                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormOne)
import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
  Container,
} from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { blue, orange } from "@material-ui/core/colors";
import { GetData, PostData } from "../../api/service";
import PrintForm from "./PrintForm";
import ProfileLanding from "./ProfileLanding";
import ReactToPrint from "react-to-print";
import AlertDiv from "../AlertDiv";

import queryString from "query-string";

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});
const searchData = [
  {
    key: "journals",
    label: "Published Papers in Journals",
  },
  {
    key: "journal_paper",
    label: "Published Paper in Journals notified on UGC website",
  },
  {
    key: "articles",
    label: "Articles/Chapters published in Books",
  },
  {
    key: "full_papers",
    label: "Full Papers in Conference Proceedings",
  },
  {
    key: "books_published",
    label: "Books Published as Single or as Editor",
  },
];

class ApiReportPublication extends Component {
  state = {
    year: "",
    search: "",
    data: [],
    isLoaded: false,
    edit: false,
    params: queryString.parse(this.props.history.location.search),
  };

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = () => {
    //fetch data

    if (this.props.public == true) {
      PostData(`/getemployeeforprofilesearch-public`, {
        search: this.state.params.key,
      }).then((resp) => {
        if (Array.isArray(resp) && resp.length > 0) {
          this.setState({
            data: resp,
            search: this.state.params.key,
            isLoaded: true,
          });
        } else {
          this.setState({
            isLoaded: true,
            search: "",
            data: [],
          });
        }
      });
    } else {
      if (this.state.year !== "") {
        GetData(`/${this.state.year}/getemployeeforprofile`).then((resp) => {
          if (Array.isArray(resp) && resp.length > 0) {
            this.setState({
              data: resp,
              isLoaded: true,
            });
          } else {
            this.setState({
              isLoaded: true,
              data: [],
            });
          }
        });
      } else if (this.state.search !== "") {
        GetData(`/${this.state.search}/getemployeeforprofilesearch`).then(
          (resp) => {
            //console.log(resp)
            if (Array.isArray(resp) && resp.length > 0) {
              this.setState({
                data: resp,
                isLoaded: true,
              });
            } else {
              this.setState({
                isLoaded: true,
                data: [],
              });
            }
          }
        );
      }
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        isLoaded: false,
        data: [],
      },
      () => {
        // this.getInitialData();

        PostData(`/getemployeepublications`, {
          year: this.state.year,
          search: this.state.search,
        }).then((resp) => {
          if (Array.isArray(resp) && resp.length > 0) {
            this.setState({
              data: resp,
              isLoaded: true,
            });
          } else {
            this.setState({
              isLoaded: true,
              data: [],
            });
          }
        });
      }
    );
  };

  render() {
    let { classes } = this.props;
    return (
      <div>
        {this.props.public == false && (
          <form onSubmit={this.onSubmit}>
            <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  style={{
                    color: blue[700],
                  }}
                >
                  Select type and year to get list of publications.
                </Typography>
                <br />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Type of data required
                    </Typography>
                    <select
                      className="form-control"
                      type="text"
                      name="search"
                      value={this.state.search}
                      onChange={(e) =>
                        this.setState({
                          search: e.target.value,
                          //email: "",
                          isLoaded: false,
                          data: [],
                        })
                      }
                    >
                      <option value={``} disabled>
                        Select One
                      </option>
                      {searchData.map((el, index) => (
                        <option value={el.key}>{el.label}</option>
                      ))}
                    </select>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Year
                    </Typography>
                    <input
                      className="form-control"
                      type="text"
                      name="year"
                      value={this.state.year}
                      onChange={(e) =>
                        this.setState({
                          year: e.target.value,
                          //search: "",
                          isLoaded: false,
                          data: [],
                        })
                      }
                      placeholder="Year"
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={classes.cardActions}>
                <Button
                  variant="contained"
                  className={classes.buttonSubmit}
                  type="submit"
                >
                  Search
                </Button>
              </CardActions>
            </Card>
          </form>
        )}

        {this.state.isLoaded && this.state.data.length == 0 && (
          <Card>
            <CardContent>No Data Found</CardContent>
          </Card>
        )}

        {this.state.isLoaded &&
        this.state.search !== "" &&
        this.state.data.length > 0 ? (
          <div style={{ backgroundColor: "white" }}>
            <Card>
              <CardContent>
                {this.props.public == false && (
                  <div style={{ textAlign: "center" }}>
                    <AlertDiv back={orange[100]} border={orange[400]}>
                      You can print this form or can save as pdf. Click on the
                      button below.
                    </AlertDiv>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          Print or Save
                        </Button>
                      )}
                      ref={(el) => (this.buttonRef = el)}
                      content={() => this.componentRef}
                      pageStyle={{ margin: "10px" }}
                      copyStyles={true}
                    />

                   
                  </div>
                )}

                {this.props.public == true && (
                  <div style={{ textAlign: "center" }}>
                    <img src="/icon512.png" style={{ width: 100 }} />
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      B.H College, Howly, Barpeta
                    </Typography>
                    <Typography variant="body2"></Typography>
                    <Typography variant="body2" gutterBottom>
                      Assam
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 700 }}>
                      <u>Faculty API Reports</u>
                    </Typography>
                    <br />

                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          Print or Save
                        </Button>
                      )}
                      ref={(el) => (this.buttonRef = el)}
                      content={() => this.componentRef}
                      pageStyle={{ margin: "10px" }}
                      copyStyles={true}
                    />
                  </div>
                )}
                <br />

                <div ref={(el) => (this.componentRef = el)}>
                  <Typography
                    variant="h5"
                    style={{
                      color: blue[700],
                      textAlign: "center",
                    }}
                  >
                    {
                      searchData.find((el) => el.key == this.state.search)
                        ?.label
                    }
                  </Typography>

                  <div style={{ textAlign: "center" }}>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table={`table-to-xls_${this.state.search}`}
                      filename={`data_export_${this.state.search}_${this.state.year}`}
                      sheet="tablexls"
                      buttonText="Download as XLS"
                    />
                  </div>

                  {this.state.isLoaded &&
                  this.state.search !== "" &&
                  this.state.search === "journals" ? (
                    <>
                      <table
                        width="100%"
                        id={`table-to-xls_${this.state.search}`}
                      >
                        <tbody>
                          <tr>
                            <td>
                              {this.state.search === "journals"
                                ? this.state.data.map(
                                    (el1, index) =>
                                      el1.journals?.length > 0 && (
                                        <table
                                          width="100%"
                                          className="table"
                                          key={index}
                                        >
                                          <thead>
                                            <tr>
                                              <td colSpan={8} align="center">
                                                <Typography
                                                  style={{
                                                    fontWeight: 700,
                                                    fontSize: 15,
                                                  }}
                                                  variant="h5"
                                                >
                                                  {el1.name} ( Department ::{" "}
                                                  {el1.dept_name})
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="30%">
                                                Title with Page Numbers
                                              </td>
                                              <td width="20%">Journal</td>

                                              <td>ISSN/ ISBN No.</td>
                                              <td>
                                                Whether Peer Reviewed Impact
                                                Factor, if any{" "}
                                              </td>
                                              <td>No. of Co-author</td>
                                              <td>
                                                Whether you are the main author
                                              </td>
                                              <td>API Score</td>
                                              <td>Year</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el1.journals.map((el, idx) => (
                                              <tr key={idx}>
                                                <td>
                                                  {idx + 1}. {el.title}
                                                </td>
                                                <td>{el.journal}</td>
                                                <td>{el.issn}</td>
                                                <td>{el.impact_factor}</td>
                                                <td>{el.no_co_au}</td>
                                                <td>{el.main_au}</td>
                                                <td>{el.api_score}</td>
                                                <td>{el.year}</td>
                                              </tr>
                                            ))}

                                            {/* <tr>
                                      <td colSpan={6} align="right">
                                        Total
                                      </td>
                                      <td align="left">{el1.journal_total}</td>
                                    </tr> */}
                                          </tbody>
                                        </table>
                                      )
                                  )
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}

                  {this.state.isLoaded &&
                  this.state.search !== "" &&
                  this.state.search === "articles" ? (
                    <>
                      <table
                        width="100%"
                        id={`table-to-xls_${this.state.search}`}
                      >
                        <tbody>
                          <tr>
                            <td>
                              {this.state.search === "articles"
                                ? this.state.data.map(
                                    (el1, index) =>
                                      el1.articles?.length > 0 && (
                                        <table
                                          width="100%"
                                          className="table"
                                          key={index}
                                          id={`table-to-xls_${this.state.search}`}
                                        >
                                          <thead>
                                            <tr>
                                              <td colSpan={8} align="center">
                                                <Typography
                                                  style={{
                                                    fontWeight: 700,
                                                    fontSize: 15,
                                                  }}
                                                  variant="h5"
                                                >
                                                  {el1.name} (Department ::{" "}
                                                  {el1.dept_name})
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="30%">
                                                Title with Page Numbers
                                              </td>
                                              <td width="20%">
                                                Book Title, editor & publisher
                                                and Year
                                              </td>

                                              <td>ISSN/ ISBN No.</td>
                                              <td>
                                                Whether Peer Reviewed Impact
                                                Factor, if any{" "}
                                              </td>
                                              <td>No. of Co-author</td>
                                              <td>
                                                Whether you are the main author
                                              </td>
                                              <td>
                                                Whether at the time of
                                                publication Affiliating
                                                Institution was same Yes/ No
                                              </td>
                                              <td>API Score</td>
                                              <td>Year</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el1.articles.map((el, idx) => (
                                              <tr key={idx}>
                                                <td>
                                                  {idx + 1}. {el.title}
                                                </td>
                                                <td>{el.publisher}</td>
                                                <td>{el.issn}</td>
                                                <td>{el.impact_factor}</td>
                                                <td>{el.no_co_au}</td>
                                                <td>{el.main_au}</td>
                                                <td>{el.institution}</td>
                                                <td>{el.api_score}</td>
                                                <td>{el.year}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      )
                                  )
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}

                  {this.state.isLoaded &&
                  this.state.search !== "" &&
                  this.state.search === "full_papers" ? (
                    <>
                      <table
                        width="100%"
                        id={`table-to-xls_${this.state.search}`}
                      >
                        <tbody>
                          <tr>
                            <td>
                              {this.state.search === "full_papers"
                                ? this.state.data.map(
                                    (el1, index) =>
                                      el1.full_papers?.length > 0 && (
                                        <table
                                          width="100%"
                                          className="table"
                                          key={index}
                                          id={`table-to-xls_${this.state.search}`}
                                        >
                                          <thead>
                                            <tr>
                                              <td colSpan={8} align="center">
                                                <Typography
                                                  style={{
                                                    fontWeight: 700,
                                                    fontSize: 15,
                                                  }}
                                                  variant="h5"
                                                >
                                                  {el1.name} (Department ::{" "}
                                                  {el1.dept_name})
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20%">
                                                Title with Page Numbers
                                              </td>
                                              <td width="40%">
                                                Title, editor & publisher and
                                                Year
                                              </td>

                                              <td>ISSN/ ISBN No.</td>

                                              <td>No. of Co-author</td>
                                              <td>
                                                Whether you are the main author
                                              </td>
                                              <td>
                                                Whether at the time of
                                                publication Affiliating
                                                Institution was same Yes/ No
                                              </td>
                                              <td>API Score</td>
                                              <td>Year</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el1.full_papers.map((el, idx) => (
                                              <tr key={idx}>
                                                <td>
                                                  {idx + 1}. {el.title}
                                                </td>
                                                <td>{el.details}</td>
                                                <td>{el.issn}</td>
                                                <td>{el.no_co_au}</td>
                                                <td>{el.main_au}</td>
                                                <td>{el.institution}</td>
                                                <td>{el.api_score}</td>
                                                <td>{el.year}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      )
                                  )
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}

                  {this.state.isLoaded &&
                  this.state.search !== "" &&
                  this.state.search === "books_published" ? (
                    <>
                      <table
                        width="100%"
                        id={`table-to-xls_${this.state.search}`}
                      >
                        <tbody>
                          <tr>
                            <td>
                              {this.state.search === "books_published"
                                ? this.state.data.map(
                                    (el1, index) =>
                                      el1.books_published?.length > 0 && (
                                        <table
                                          width="100%"
                                          className="table"
                                          key={index}
                                          id={`table-to-xls_${this.state.search}`}
                                        >
                                          <thead>
                                            <tr>
                                              <td colSpan={9} align="center">
                                                <Typography
                                                  style={{
                                                    fontWeight: 700,
                                                    fontSize: 15,
                                                  }}
                                                  variant="h5"
                                                >
                                                  {el1.name} (Department ::{" "}
                                                  {el1.dept_name})
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="20%">
                                                Name of Book and year published
                                              </td>
                                              <td width="10%">
                                                Type of Book & Authorship
                                              </td>

                                              <td width="20%">
                                                Publisher & ISSN/ ISBN No.
                                              </td>
                                              <td>Whether Peer Reviewed</td>

                                              <td>No. of Co-author</td>
                                              <td>
                                                Whether you are the main author
                                              </td>
                                              <td width="10%">
                                                Whether at the time of
                                                publication Affiliating
                                                Institution was same Yes/ No
                                              </td>
                                              <td>API Score</td>
                                              <td>Year</td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el1.books_published.map(
                                              (el, idx) => (
                                                <tr key={idx}>
                                                  <td>
                                                    {idx + 1}. {el.title}
                                                  </td>
                                                  <td>{el.type}</td>
                                                  <td>{el.publisher_issn}</td>
                                                  <td>{el.peer}</td>
                                                  <td>{el.no_co_au}</td>
                                                  <td>{el.main_au}</td>
                                                  <td>{el.institution}</td>
                                                  <td>{el.api_score}</td>
                                                  <td>{el.year}</td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      )
                                  )
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}

                  {this.state.isLoaded &&
                  this.state.search !== "" &&
                  this.state.search === "journal_paper" ? (
                    <>
                      <table
                        width="100%"
                        id={`table-to-xls_${this.state.search}`}
                      >
                        <tbody>
                          <tr>
                            <td>
                              {this.state.search === "journal_paper"
                                ? this.state.data.map(
                                    (el1, index) =>
                                      el1.journal_paper?.length > 0 && (
                                        <table
                                          width="100%"
                                          className="table"
                                          key={index}
                                          id={`table-to-xls_${this.state.search}`}
                                        >
                                          <thead>
                                            <tr>
                                              <td colSpan={8} align="center">
                                                <Typography
                                                  style={{
                                                    fontWeight: 700,
                                                    fontSize: 15,
                                                  }}
                                                  variant="h5"
                                                >
                                                  {el1.name} (Department ::{" "}
                                                  {el1.dept_name})
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td rowSpan={2}>
                                                Title of the paper
                                              </td>
                                              <td rowSpan={2}>
                                                Name of the journal
                                              </td>

                                              <td rowSpan={2}>
                                                Year of Publication.
                                              </td>
                                              <td rowSpan={2}>ISSN No. </td>
                                              <td colSpan={3}>
                                                Link to the recognition in UGC
                                                enlistment of the Journal/
                                                Digital Object Identifier (doi)
                                                No.
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Link to Website of the Journal
                                              </td>
                                              <td>
                                                Link to article/ paper/ abstract
                                                of the article
                                              </td>
                                              <td>
                                                Is it listed in UGC Carelist
                                                /Scopus/ Web of Science / Other,
                                                Mention
                                              </td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el1.journal_paper.map(
                                              (el, idx) => (
                                                <tr key={idx}>
                                                  <td>
                                                    {idx + 1}. {el.title}
                                                  </td>
                                                  <td>{el.journal}</td>
                                                  <td>{el.year}</td>
                                                  <td>{el.issn}</td>
                                                  <td>
                                                    <a
                                                      target="_blank"
                                                      style={{ color: "blue" }}
                                                      href={el.website_link}
                                                    >
                                                      View Document
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a
                                                      target="_blank"
                                                      style={{ color: "blue" }}
                                                      href={el.article_link}
                                                    >
                                                      View Document
                                                    </a>
                                                  </td>
                                                  <td>
                                                    {el.listed_main} &nbsp;{" "}
                                                    {el.listed}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      )
                                  )
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}

                  {this.state.isLoaded &&
                  this.state.search !== "" &&
                  this.state.search === "journal_paper_c" ? (
                    <>
                      <table
                        width="100%"
                        id={`table-to-xls_${this.state.search}`}
                      >
                        <tbody>
                          <tr>
                            <td>
                              {this.state.search === "journal_paper_c"
                                ? this.state.data.map(
                                    (el1, index) =>
                                      el1.journal_paper?.filter(
                                        (el) => el.listed_main == "UGC Carelist"
                                      ).length > 0 && (
                                        <table
                                          width="100%"
                                          className="table"
                                          key={index}
                                          id={`table-to-xls_${this.state.search}`}
                                        >
                                          <thead>
                                            <tr>
                                              <td colSpan={8} align="center">
                                                <Typography
                                                  style={{
                                                    fontWeight: 700,
                                                    fontSize: 15,
                                                  }}
                                                  variant="h5"
                                                >
                                                  {el1.name} (Department ::{" "}
                                                  {el1.dept_name})
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td rowSpan={2}>
                                                Title of the paper
                                              </td>
                                              <td rowSpan={2}>
                                                Name of the journal
                                              </td>

                                              <td rowSpan={2}>
                                                Year of Publication.
                                              </td>
                                              <td rowSpan={2}>ISSN No. </td>
                                              <td colSpan={3}>
                                                Link to the recognition in UGC
                                                enlistment of the Journal/
                                                Digital Object Identifier (doi)
                                                No.
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Link to Website of the Journal
                                              </td>
                                              <td>
                                                Link to article/ paper/ abstract
                                                of the article
                                              </td>
                                              <td>
                                                Is it listed in UGC Carelist
                                                /Scopus/ Web of Science / Other,
                                                Mention
                                              </td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {el1.journal_paper
                                              .filter(
                                                (el) =>
                                                  el.listed_main ==
                                                  "UGC Carelist"
                                              )
                                              .map((el, idx) => (
                                                <tr key={idx}>
                                                  <td>
                                                    {idx + 1}. {el.title}
                                                  </td>
                                                  <td>{el.journal}</td>
                                                  <td>{el.year}</td>
                                                  <td>{el.issn}</td>
                                                  <td>
                                                    <a
                                                      target="_blank"
                                                      style={{ color: "blue" }}
                                                      href={el.website_link}
                                                    >
                                                      View Document
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a
                                                      target="_blank"
                                                      style={{ color: "blue" }}
                                                      href={el.article_link}
                                                    >
                                                      View Document
                                                    </a>
                                                  </td>
                                                  <td>
                                                    {el.listed_main} &nbsp;{" "}
                                                    {el.listed}
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                      )
                                  )
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}
                </div>
              </CardContent>
            </Card>
          </div>
        ) : null}

        {/* {this.state.isLoaded && this.state.email !== "" ? (
          this.state.data.length ? (
            <>
              <br />
              {!this.state.edit && (
                <div className={classes.cardActions}>
                  <Button
                    onClick={() =>
                      this.setState({
                        edit: true,
                      })
                    }
                    style={{ marginRight: "2em" }}
                    color="secondary"
                    size="small"
                    variant="contained"
                    type="button"
                  >
                    EDIT
                  </Button>
                </div>
              )}
              <br />
              {!this.state.edit && (
                <PrintForm
                  history={this.props.history}
                  user={this.props.user}
                  data={this.state.data}
                />
              )}

              {this.state.edit && (
                <ProfileLanding
                  history={this.props.history}
                  user={this.state.data[0]}
                  data={this.state.data}
                />
              )}
            </>
          ) : (
            <Card>
              <CardContent>
                <Typography variant="h5">No data found.</Typography>
              </CardContent>
            </Card>
          )
        ) : (
          ""
        )} */}

        {/* {this.state.isLoaded &&
          this.state.email === "" &&
          this.state.search === "" && (
            <Card>
              <CardContent>
                <div style={{ overflowX: "scroll" }}>
                  <table width="100%" className="table">
                    <thead>
                      <tr>
                        <td>
                          <b>#</b>
                        </td>
                        <td>
                          <b>Name</b>
                        </td>
                        <td>
                          <b>Department</b>
                        </td>
                        <td>
                          <b>Email</b>
                        </td>
                        <td>
                          <b>Phone</b>
                        </td>
                        <td>
                          <b>Link</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data?.map((el, index) => (
                        <tr key={index}>
                          <td style={{ fontSize: "20px" }}>{index + 1}</td>
                          <td style={{ fontSize: "20px" }}>{el.name}</td>
                          <td style={{ fontSize: "20px" }}>{el.dept_code}</td>
                          <td style={{ fontSize: "20px" }}>{el.email}</td>
                          <td style={{ fontSize: "20px" }}>{el.phone}</td>
                          <td style={{ fontSize: "20px" }}>
                            <a
                              href={`/apireportwithemail/${el.email}`}
                              target="_blank"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>
          )} */}
      </div>
    );
  }
}

export default withStyles(styles)(ApiReportPublication);

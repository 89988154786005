import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
} from "@material-ui/core";

import { blue, orange } from "@material-ui/core/colors";
import { GetData } from "../../api/service";
import PrintForm from "./PrintForm";
import ProfileLanding from "./ProfileLanding";
import ReactToPrint from "react-to-print";
import AlertDiv from "../AlertDiv";

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});
const searchData = [
  {
    key: "activities",
    label: "Activities",
  },
  {
    key: "journals",
    label: "Journals",
  },
  {
    key: "articles",
    label: "Articles",
  },
  {
    key: "full_papers",
    label: "Full Papers",
  },
  {
    key: "books_published",
    label: "Books Published",
  },
  {
    key: "on_going_projects",
    label: "On Going Projects",
  },
  {
    key: "completed_projects",
    label: "Completed Projects",
  },
  {
    key: "research_guidance",
    label: "Research Guidance",
  },
  {
    key: "training_courses",
    label: "Training Courses",
  },
  {
    key: "papers_presented",
    label: "Papers Presented",
  },
  {
    key: "invited_lectures",
    label: "Invited Lectures",
  },
  {
    key: "journal_paper",
    label: "Journal Paper",
  },
  {
    key: "teaching_learning",
    label: "Teaching Learning",
  },
  {
    key: "teaching_learning_score",
    label: "Teaching Learning Score",
  },
  {
    key: "reading_material",
    label: "Reading Material",
  },
  {
    key: "participation_innovation",
    label: "Participation Innovation",
  },
  {
    key: "examination_duty",
    label: "Examination Duty",
  },
];

class ApiReportAll extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  getInitialData = () => {
    
      GetData(`/getapireportall`).then((resp) => {
        //console.log(resp)
        if (Array.isArray(resp) && resp.length > 0) {
          this.setState({
            data: resp,
            isLoaded: true,
          });
        } else {
          this.setState({
            isLoaded: true,
            data: [],
          });
        }
      });
    
  };

 componentDidMount(){
    this.getInitialData();
 }

  render() {
    let { classes } = this.props;
    return (
      <div>
     

        {this.state.isLoaded && this.state.data.length > 0 ? ( <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>

            <div
                    style={{ textAlign: 'center' }}
                >
                    <AlertDiv
                        back={orange[100]}
                        border={orange[400]}
                    >
                        You can print this form or can save as pdf. Click on the button below.
                    </AlertDiv>
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"

                            >
                                Print or Save
                        </Button>
                        }
                        ref={el => this.buttonRef = el}
                        content={() => this.componentRef}
                        pageStyle={{ margin: '10px' }}
                        copyStyles={true}
                    />
                </div>
                <br />

                <div ref={el => (this.componentRef = el)}>
       
            <Typography
            variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'activities')?.label}
            </Typography>
           
               {this.state.data.map(
                  (el1, index) =>
                    el1.activities?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={3} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="70%">Activity Name</td>
                            <td>Period</td>
                            <td>API claimed</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.activities.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.activity_name}
                              </td>
                              <td>{el.period}</td>
                              <td>{el.api_claimed}</td>
                              
                              
                            </tr>
                          ))}

                         
                          <tr>
                            <td colSpan={2} align="right">
                              Total
                            </td>
                            <td align="left">{el1.activity_total}</td>
                          </tr>
                        </tbody>
                      </table>
                    )
                )}
                <br />
              <hr />
              <Typography
            variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'journals')?.label}
            </Typography>
      

                    {this.state.data.map(
                  (el1, index) =>
                    el1.journals?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Title with Page Numbers</td>
                            <td width="20%">Journal</td>

                            <td>ISSN/ ISBN No.</td>
                            <td>
                              Whether Peer Reviewed Impact Factor, if any{" "}
                            </td>
                            <td>No. of Co-author</td>
                            <td>Whether you are the main author</td>
                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.journals.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.title}
                              </td>
                              <td>{el.journal}</td>
                              <td>{el.issn}</td>
                              <td>{el.impact_factor}</td>
                              <td>{el.no_co_au}</td>
                              <td>{el.main_au}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}

                        
                          <tr>
                            <td colSpan={6} align="right">
                              Total
                            </td>
                            <td align="left">
                              {el1.journal_total}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )
                )
        }

<br />
              <hr />
                <Typography
            variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'articles')?.label}
            </Typography>

       {this.state.data.map(
                  (el1, index) =>
                    el1.articles?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Title with Page Numbers</td>
                            <td width="20%">
                              Book Title, editor & publisher and Year
                            </td>

                            <td>ISSN/ ISBN No.</td>
                            <td>
                              Whether Peer Reviewed Impact Factor, if any{" "}
                            </td>
                            <td>No. of Co-author</td>
                            <td>Whether you are the main author</td>
                            <td>
                              Whether at the time of publication Affiliating
                              Institution was same Yes/ No
                            </td>
                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.articles.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.title}
                              </td>
                              <td>{el.publisher}</td>
                              <td>{el.issn}</td>
                              <td>{el.impact_factor}</td>
                              <td>{el.no_co_au}</td>
                              <td>{el.main_au}</td>
                              <td>{el.institution}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                }

<br />
              <hr />
<Typography
            variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'full_papers')?.label}
            </Typography>

       {this.state.data.map(
                  (el1, index) =>
                    el1.full_papers?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">Title with Page Numbers</td>
                            <td width="40%">
                              Title, editor & publisher and Year
                            </td>

                            <td>ISSN/ ISBN No.</td>

                            <td>No. of Co-author</td>
                            <td>Whether you are the main author</td>
                            <td>
                              Whether at the time of publication Affiliating
                              Institution was same Yes/ No
                            </td>
                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.full_papers.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.title}
                              </td>
                              <td>{el.details}</td>
                              <td>{el.issn}</td>
                              <td>{el.no_co_au}</td>
                              <td>{el.main_au}</td>
                              <td>{el.institution}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
            }

<br />
              <hr />
                <Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'books_published')?.label}
            </Typography>

        {this.state.data.map(
                  (el1, index) =>
                    el1.books_published?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">Name of Book and year published</td>
                            <td width="10%">Type of Book & Authorship</td>

                            <td width="20%">Publisher & ISSN/ ISBN No.</td>
                            <td>Whether Peer Reviewed</td>

                            <td>No. of Co-author</td>
                            <td>Whether you are the main author</td>
                            <td>
                              Whether at the time of publication Affiliating
                              Institution was same Yes/ No
                            </td>
                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.books_published.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.title}
                              </td>
                              <td>{el.type}</td>
                              <td>{el.publisher_issn}</td>
                              <td>{el.peer}</td>
                              <td>{el.no_co_au}</td>
                              <td>{el.main_au}</td>
                              <td>{el.institution}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
                <Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'on_going_projects')?.label}
            </Typography>

        {this.state.data.map(
                  (el1, index) =>
                    el1.on_going_projects?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Title</td>
                            <td width="20%">Agency</td>

                            <td>Period</td>
                            <td>Grant/ Amount Mobilized (Rs. Lakh)</td>

                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.on_going_projects.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.title}
                              </td>
                              <td>{el.agency}</td>
                              <td>{el.period}</td>
                              <td>{el.grant}</td>

                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
        }

<br />
              <hr />
                <Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'completed_projects')?.label}
            </Typography>

 {this.state.data.map(
                  (el1, index) =>
                    el1.completed_projects?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Title</td>
                            <td width="20%">Agency</td>

                            <td>Period</td>
                            <td>Grant/ Amount Mobilized (Rs. Lakh)</td>
                            <td>Whether policy document/ patent as outcome</td>

                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.completed_projects.map((el, idx) => (
                            <tr key={idx}>
                              <td>{el.title}</td>
                              <td>{el.agency}</td>
                              <td>{el.period}</td>
                              <td>{el.grant}</td>
                              <td>{el.document}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
                <Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'research_guidance')?.label}
            </Typography>

        {this.state.data.map(
                  (el1, index) =>
                    el1.research_guidance?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>Level of Guidance</td>
                            <td rowSpan={2}>Number Enrolled</td>

                            <td colSpan={2} align="left">
                              Thesis/Dissertation submitted
                            </td>

                            <td colSpan={2} align="left">
                              Degree Awarded
                            </td>

                            <td rowSpan={2}>Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.research_guidance.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.level}
                              </td>
                              <td>{el.number}</td>
                              <td>{el.th_period}</td>
                              <td>{el.th_number}</td>
                              <td>{el.d_period}</td>
                              <td>{el.d_number}</td>
                              <td>{el.score}</td>
                            </tr>
                          ))}

                                <tr>
                            <td colSpan={5} align="right">Total</td>
                            <td colSpan={2} align="left">{el1.research_total}</td>
                            </tr>
                        </tbody>
                      </table>
                    )
                )
            }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'training_courses')?.label}
            </Typography>

        {this.state.data.map(
                  (el1, index) =>
                    el1.training_courses?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td>Programme</td>
                            <td>Duration</td>

                            <td>Organized By</td>

                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.training_courses.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.programme}
                              </td>
                              <td>{el.duration}</td>
                              <td>{el.orgainised_by}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'papers_presented')?.label}
            </Typography>

        {this.state.data.map(
                  (el1, index) =>
                    el1.papers_presented?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">Title of the paper presented </td>
                            <td width="20%">Title of Conference Seminar</td>

                            <td>Organized By</td>
                            <td>
                              Whether international/ national/ state/ regional/
                              college or university
                            </td>

                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.papers_presented.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.paper}
                              </td>
                              <td>{el.conference}</td>
                              <td>{el.orgainised_by}</td>
                              <td>{el.type}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'invited_lectures')?.label}
            </Typography>

            {this.state.data.map(
                  (el1, index) =>
                    el1.invited_lectures?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">
                              Title of Lecture/ Academic Session
                            </td>
                            <td width="20%">Title of Conference/ Seminar</td>

                            <td>Organized By</td>
                            <td>
                              Whether international/ national/ state/ regional/
                              college or university
                            </td>

                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.invited_lectures.map((el, idx) => (
                            <tr key={idx}>
                              <td>
                                {idx + 1}. {el.paper}
                              </td>
                              <td>{el.conference}</td>
                              <td>{el.orgainised_by}</td>
                              <td>{el.type}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'journal_paper')?.label}
            </Typography>

 {this.state.data.map(
                  (el1, index) =>
                    el1.journal_paper?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>Title of the paper</td>
                            <td rowSpan={2}>Name of the journal</td>
                           
                            <td rowSpan={2}>Year of Publication.</td>
                            <td rowSpan={2}>ISSN No. </td>
                            <td colSpan={3}>Link to the recognition in UGC enlistment of the Journal/ Digital Object Identifier (doi) No.</td>
                                
                        </tr>
                        <tr>
                            <td>Link to Website of the Journal</td>
                            <td>Link to article/ paper/ abstract of the article</td>
                            <td>Is it listed in UGC Carelist /Scopus/ Web of Science / Other, Mention</td>
                        </tr>
                        </thead>
                        <tbody>
                          {el1.journal_paper.map((el, idx) => (
                            <tr key={idx}>
                               <td>{idx+1}. {el.title}</td>
                                <td>{el.journal}</td>
                                <td>{el.year}</td>
                                <td>{el.issn}</td>
                                <td>{el.website_link}</td>
                                <td>{el.article_link}</td>
                                <td>{el.listed}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'teaching_learning')?.label}
            </Typography>

 {this.state.data.map(
                  (el1, index) =>
                    el1.teaching_learning?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                          <td >Course</td>
                            <td >Level</td>
                           
                            <td >Mode of teaching</td>
                                        
                           
                            <td >Hours per week alloted</td>
                            <td>% of classes taken as per documented record</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.teaching_learning.map((el, idx) => (
                            <tr key={idx}>
                              <td>{idx+1}. {el.course}</td>
                                <td>{el.level}</td>
                                <td>{el.mode}</td>
                                <td>{el.hour}</td>
                                <td>{el.percentage}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'teaching_learning_score')?.label}
            </Typography>

 {this.state.data.map(
                  (el1, index) =>
                    el1.teaching_learning_score?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                          <td></td>
                            <td>API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.teaching_learning_score.map((el, idx) => (
                            <tr key={idx}>
                              <td>{idx+1}. {el.class}</td>
                                <td>{el.class_api}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }


<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'reading_material')?.label}
            </Typography>
{this.state.data.map(
                  (el1, index) =>
                    el1.reading_material?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                          <td >Course/Paper</td>
                            <td >Consulted</td>
                           
                            <td >Prescribed</td>
                                        
                           
                            <td >Additional
                                resource
                                provided
                                </td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.reading_material.map((el, idx) => (
                            <tr key={idx}>
                              <td>{idx+1}. {el.course}</td>
                                <td>{el.consulted}</td>
                                <td>{el.prescribed}</td>
                                <td>{el.resource}</td>       
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'participation_innovation')?.label}
            </Typography>


 {this.state.data.map(
                  (el1, index) =>
                    el1.participation_innovation?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                          <td >Short Description</td>
                            <td >API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.participation_innovation.map((el, idx) => (
                            <tr key={idx}>
                              <td >{idx+1}. {el.description}</td>
                                <td>{el.api_score}</td>    
                            </tr>
                          ))}

<tr>
                            <td >Total Score</td>
                            <td>{el1.participation_innovation.reduce((pre,curr)=>parseFloat(pre)+parseFloat(curr.api_score),0)}</td>
                            </tr>
                        </tbody>
                      </table>
                    )
                )
                          }

<br />
              <hr />
<Typography
                variant="h5"
                style={{
                  color: blue[700],
                  textAlign:'center'
                }}
            >
                {searchData.find((el)=>el.key == 'examination_duty')?.label}
            </Typography>

            {this.state.data.map(
                  (el1, index) =>
                    el1.examination_duty?.length > 0 && (
                      <table width="100%" className="table" key={index}>
                        <thead>
                          <tr>
                            <td colSpan={8} align="center">
                              {el1.name}
                              <br />
                              Department :: {el1.dept_name}
                            </td>
                          </tr>
                          <tr>
                          <td >Type of Examination Duties</td>
                            <td>Duties Assigned</td>
                           <td>Extend to which carried out (%)</td>
                           <td >API Score</td>
                          </tr>
                        </thead>
                        <tbody>
                          {el1.examination_duty.map((el, idx) => (
                            <tr key={idx}>
                               <td >{idx+1}. {el.type}</td>
                                <td>{el.duty}</td>
                                <td>{el.extend}</td>
                                <td>{el.api_score}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={3}>Total Score</td>
                            <td>{el1.examination_duty?.reduce((pre,curr)=>parseFloat(pre)+parseFloat(curr.api_score),0)}</td>
                            </tr>
                        </tbody>
                      </table>
                    )
                )
                          }

</div>

        </CardContent>
        </Card>):null}


      </div>
    );
  }
}

export default withStyles(styles)(ApiReportAll);

import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';

import { blue } from '@material-ui/core/colors';
import 'react-confirm-alert/src/react-confirm-alert.css';

import TeachingLearning from './TeachingLearning';
import TeachingLearningScore from './TeachingLearningScore';
import ReadingMaterial from './ReadingMaterial';
import ParticipationInnovation from './ParticipationInnovation';
import ExaminationDuty from './ExaminationDuty';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})



class FormSix extends Component {

    state = {
        teaching_learning:[],
        teaching_learning_score:[],
        reading_material:[],
       
        reading_material_text:'API score based on Preparation and imparting of knowledge/instruction as per curriculum & syllabus enrichment by providing additional resources to students',
        reading_material_score:'',
        participation_innovation:[],
        examination_duty:[],
        phone: this.props.user.phone,
        email: this.props.user.email,
        step:5,
        total:0,
        other_info:'',
        step6_remarks:''
    }

    setRead=(reading_material_score)=>{
            this.setState({
                reading_material_score 
            })
    }

    setExaminationDuty = (examination_duty) =>{
        //console.log(research_guidance)
        this.setState({
            examination_duty
        })
    }

    setParticipationInnovation = (participation_innovation) =>{
        //console.log(research_guidance)
        this.setState({
            participation_innovation
        })
    }

    setTeachingLearning = (teaching_learning) =>{
        //console.log(training_courses)
        this.setState({
            teaching_learning
        })
    }

    setTeachingLearningScore = (teaching_learning_score) =>{
        //console.log(training_courses)
        this.setState({
            teaching_learning_score
        })
    }


    setReadingMaterial = (reading_material) =>{
        //console.log(training_courses)
        this.setState({
            reading_material
        })
    }



    componentDidMount() {


        let data = this.props.data;
        //console.log(data)
       
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
                total:data[0].research_total
            })
        }

    }

  


    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0],
                    total:data[0].research_total

                })
            }
        }

    }

    

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

       
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.setData(this.state);
       

    }

   
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div style={{ borderLeft: 'solid 5px #1715DA',paddingLeft:'10px' }}>
                    <Typography
                        variant="h5"
                    >
                        Application Form
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>

                    </div>

                   

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>

                        <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                             PART B : ACADEMIC PERFORMANCE INDICATOR<br />
                             ( Please see details instructions of this PBAS proformas before filling out this section)<br />
                           CATEGORY I : TEACHING, LEARNING AND EVALUATION RELATED ACTIVITIES 

                            </Typography>
                            <br />



                            <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                          (i) Lectures, Seminars, Tutorials, Practical, Contact Hours (give semester-wise details, where necessary) :
                            </Typography>
                                <TeachingLearning setTeachingLearning={this.setTeachingLearning} teaching_learning={this.state.teaching_learning} />
                                </Grid>

                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >

                                Lecture(L), Seminar(S), Tutorial(T), Practical(P), Contact Hours(C)
                            </Typography>
                                <TeachingLearningScore setTeachingLearningScore={this.setTeachingLearningScore} teaching_learning_score={this.state.teaching_learning_score} />
                                </Grid>

                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                           
                         (ii)  Reading/Instructional materials consulted and additional knowledge resources provided to students :
                            </Typography>
                                <ReadingMaterial setReadingMaterial={this.setReadingMaterial} reading_material={this.state.reading_material}  setRead={this.setRead}  reading_material_text={this.state.reading_material_text} reading_material_score={this.state.reading_material_score} />
                                </Grid>

                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >

(iii)  Use of participatory and innovative Teaching-Learning Methodologies/Audio-visual teaching aid, Updating of subject Content, Course Improvement etc. :
                            </Typography>
                                <ParticipationInnovation setParticipationInnovation={this.setParticipationInnovation} participation_innovation={this.state.participation_innovation} />
                                </Grid>

                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >

(iv) Examination Duties Assigned and Performed :
                            </Typography>
                                <ExaminationDuty setExaminationDuty={this.setExaminationDuty} examination_duty={this.state.examination_duty} />
                                </Grid>

                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                         
                          PART C: OTHER RELEVANT INFORMATION
                            </Typography>
                            <textarea
                                    className="form-control"
                                    type="text"
                                    name="other_info"
                                    value={this.state.other_info}
                                    onChange={this.onChange}
                                    placeholder="Please give details of any other credential, significant contributions, awards received etc. not mentioned earlier"
                                />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                         
                          Remarks (if any)
                            </Typography>
                            <textarea
                                    className="form-control"
                                    type="text"
                                    name="step6_remarks"
                                    value={this.state.step6_remarks}
                                    onChange={this.onChange}
                                    placeholder="Remarks"
                                />
                                </Grid>


                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>
                        <Button

                                    type="button"
                                    onClick={this.props.back}
                                    >
                                    Previous
                                    </Button>
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormSix)
import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import LoadingMain from './LoadingMain';
import LargeAlert from './Components/Alerts/LargeAlert';
import ProfileUpdateContainer from './Containers/ProfileUpdateContainer';
import ApiReportContainer from './Containers/ApiReportContainer';









const SettingsContainer = React.lazy(() => import('./Containers/SettingsContainer'));
const ForgotPasswordContainer = React.lazy(() => import('./Containers/ForgotPasswordContainer'));
const AttendanceContainer = React.lazy(() => import('./Containers/AttendanceContainer'));
const FeedbackContainer = React.lazy(() => import('./Containers/FeedbackContainer'));
const AttendanceReportContainer = React.lazy(() => import('./Containers/AttendanceReportContainer'));
const RoutineContainer = React.lazy(() => import('./Containers/RoutineContainer'));
const LogBookContainer = React.lazy(() => import('./Containers/LogBookContainer'));
const DiaryContainer = React.lazy(() => import('./Containers/DiaryContainer'));
const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'));


// additional modules
const AdmissionContainer = React.lazy(() => import('./Containers/AdmissionContainer'));
const FeesContainer = React.lazy(() => import('./Containers/FeesContainer'));
const ReportsContainer = React.lazy(() => import('./Containers/ReportsContainer'));
const LeaveGrantContainer = React.lazy(() => import('./Containers/LeaveGrantContainer'));
const CertificateContainer = React.lazy(() => import('./Containers/CertificateContainer'));
const ClassNotesContainer = React.lazy(() => import('./Containers/ClassNotesContainer'));
const ClasstestContainer = React.lazy(() => import('./Containers/ClasstestContainer'));
const AssesmentReportContainer = React.lazy(() => import('./Containers/AssesmentReportContainer'));
const AccountsContainer = React.lazy(() => import('./Containers/AccountsContainer'));
const HostelAdmissionContainer = React.lazy(() => import('./Containers/HostelAdmissionContainer'));
const AssignmentContainer = React.lazy(() => import('./Containers/AssignmentContainer'));
const NotificationContainer = React.lazy(() => import('./Containers/NotificationContainer'));
const ProfileOpenContainer = React.lazy(() => import('./Containers/ProfileOpenContainer'));
const ApiReportPublicLink = React.lazy(() => import('./Containers/ApiReportPublicLink'));
const InventoryContainer = React.lazy(() => import('./Containers/InventoryContainer'));
const EgovernanceContainer = React.lazy(() => import('./Containers/EgovernanceContainer'));
const EgovernanceAdvanceContainer = React.lazy(() => import('./Containers/EgovernanceAdvanceContainer'));
const EGovernanceSearchContainer = React.lazy(() => import('./Containers/EGovernanceSearchContainer'));

class App extends Component {

  render() {
    return (
      <Suspense fallback={<LoadingMain />}>

        <div>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPasswordContainer} />
            <Route exact path="/" render={(props) => <HomePage {...props} />} />
            <Route exact path="/settings" component={SettingsContainer} />
            <Route exact path="/attendance" component={AttendanceContainer} />
            <Route exact path="/takeattendance" component={AttendanceContainer} />
            <Route exact path="/editattendance" component={AttendanceContainer} />
            <Route exact path="/attendancereport" component={AttendanceContainer} />
            <Route exact path="/feedback" component={FeedbackContainer} />
            <Route exact path="/feedback/:id" component={FeedbackContainer} />

            <Route exact path="/reportattendance" component={AttendanceReportContainer} />
            <Route exact path="/routine" component={RoutineContainer} />
            <Route exact path="/logbook" component={LogBookContainer} />
            <Route exact path="/diary" component={DiaryContainer} />


            <Route exact path="/notifications" component={NotificationContainer} />


            <Route exact path="/classnotes" component={ClassNotesContainer} />


            <Route exact path="/classtest" component={ClasstestContainer} />
            <Route exact path="/classtest/addmarks" component={ClasstestContainer} />
            <Route exact path="/classtest/singlereport" component={ClasstestContainer} />

            <Route exact path="/assesmentreport" component={AssesmentReportContainer} />

            <Route exact path="/admission" component={AdmissionContainer} />
            <Route exact path="/admission/verification" component={AdmissionContainer} />
            <Route exact path="/admission/verify/:form_id" component={AdmissionContainer} />
            <Route exact path="/admission/new" component={AdmissionContainer} />
            <Route exact path="/admission/view" component={AdmissionContainer} />
            <Route exact path="/admission/major" component={AdmissionContainer} />
            <Route exact path="/admission/shortlist" component={AdmissionContainer} />
            <Route exact path="/admission/fileview" component={AdmissionContainer} />
            <Route exact path="/admission/edit" component={AdmissionContainer} />
            <Route exact path="/admission/modified" component={AdmissionContainer} />
            <Route exact path="/admission/printform" component={AdmissionContainer} />
            <Route exact path="/admission/generate-receipt" component={AdmissionContainer} />
            <Route exact path="/admission/newstudents" component={AdmissionContainer} />
            <Route exact path="/admission/librarycard" component={AdmissionContainer} />
            <Route exact path="/admission/request" component={AdmissionContainer} />
            <Route exact path="/admission/checkrequest/:course/:stream" component={AdmissionContainer} />
            <Route exact path="/admission/checkresponse/:course/:stream" component={AdmissionContainer} />
            <Route exact path="/admission/taken/:course/:stream/:semester" component={AdmissionContainer} />
            <Route exact path="/admission/taken/:course/:stream/:semester/:session_id" component={AdmissionContainer} />
            <Route exact path="/admission/library/:course/:stream/:semester" component={AdmissionContainer} />
            <Route exact path="/admission/library/:course/:stream/:semester/:session_id" component={AdmissionContainer} />
            <Route exact path="/admission/ackprint/:form_id" component={AdmissionContainer} />
            <Route exact path="/admission/receipt/:receipt_no" component={AdmissionContainer} />



            <Route exact path="/hostel" component={HostelAdmissionContainer} />
            <Route exact path="/hostel/shortlist" component={HostelAdmissionContainer} />
            <Route exact path="/hostel/verification" component={HostelAdmissionContainer} />
            <Route exact path="/hostel/request" component={HostelAdmissionContainer} />
            <Route exact path="/hostel/checkrequest/:admission_type" component={HostelAdmissionContainer} />
            <Route exact path="/hostel/checkresponse/:admission_type" component={HostelAdmissionContainer} />
            <Route exact path="/hostel/taken/:admission_type" component={HostelAdmissionContainer} />
            <Route exact path="/hostel/receipt/:receipt_no" component={HostelAdmissionContainer} />

            <Route exact path="/fees" component={FeesContainer} />
            <Route exact path="/fees/receipts" component={FeesContainer} />
            
            <Route exact path="/fees/readmission" component={FeesContainer} />
            <Route exact path="/fees/editreceipt" component={FeesContainer} />
            <Route exact path="/fees/rawreceipt" component={FeesContainer} />
            <Route exact path="/fees/rawreceiptadmission" component={FeesContainer} />
            <Route exact path="/fees/reports" component={FeesContainer} />
            <Route exact path="/fees/reports-hostel" component={FeesContainer} />
            <Route exact path="/fees/singlereceipt" component={FeesContainer} />
            <Route exact path="/fees/headwise" component={FeesContainer} />

            <Route exact path="/leavegrant" component={LeaveGrantContainer} />

            <Route exact path="/reports" component={ReportsContainer} />
            <Route exact path="/reports/newstudents" component={ReportsContainer} />
            <Route exact path="/reports/idcards" component={ReportsContainer} />
            <Route exact path="/reports/allidcards" component={ReportsContainer} />
            <Route exact path="/reports/studentedit" component={ReportsContainer} />

            <Route exact path="/certificate" component={CertificateContainer} />
            <Route exact path="/certificate/certificatetype" component={CertificateContainer} />
            <Route exact path="/certificate/certificateview" component={CertificateContainer} />
            <Route exact path="/certificate/view" component={CertificateContainer} />
            <Route exact path="/certificate/reports" component={CertificateContainer} />
            <Route exact path="/certificate/print" component={CertificateContainer} />


            <Route exact path="/accounts" component={AccountsContainer} />
            <Route exact path="/accounts/accountheads" component={AccountsContainer} />
            <Route exact path="/accounts/accountsubheads" component={AccountsContainer} />
            <Route exact path="/accounts/payments" component={AccountsContainer} />
            <Route exact path="/accounts/bankhead" component={AccountsContainer} />

            <Route exact path="/accounts/opbalancehead" component={AccountsContainer} />
            <Route exact path="/accounts/opbalanceaccounts" component={AccountsContainer} />
            <Route exact path="/accounts/cashbook" component={AccountsContainer} />
            <Route exact path="/accounts/printvoucher" component={AccountsContainer} />
            <Route exact path="/accounts/cashbookfinal" component={AccountsContainer} />
            <Route exact path="/accounts/reports" component={AccountsContainer} />
            <Route exact path="/accounts/view" component={AccountsContainer} />
            <Route exact path="/accounts/taxcollection" component={AccountsContainer} />


            <Route exact path="/assignment" component={AssignmentContainer} />
            <Route exact path="/assignment/check" component={AssignmentContainer} />

            <Route exact path="/profileupdate" component={ProfileUpdateContainer} />
            <Route exact path="/apireport" component={ApiReportContainer} />
            <Route exact path="/api-report-public-link" component={ApiReportPublicLink} />
            <Route exact path="/apireportall" component={ApiReportContainer} />
            <Route exact path="/apireportpublication" component={ApiReportContainer} />
            <Route exact path="/apireportwithemail/:email" component={ProfileOpenContainer} />

            <Route exact path="/inventory" component={InventoryContainer} />
                <Route
                  exact
                  path="/inventory/groups"
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path="/inventory/subgroups"
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path="/inventory/items"
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path="/inventory/stock"
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path="/inventory/current"
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path="/inventory/issue"
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path="/inventory/barcode"
                  component={InventoryContainer}
                />
                <Route
                  exact
                  path="/inventory/return"
                  component={InventoryContainer}
                />
<Route
                  exact
                  path="/inventory/stock-register"
                  component={InventoryContainer}
                />

<Route
                  exact
                  path="/inventory/stock-issue-register"
                  component={InventoryContainer}
                />

<Route
                  exact
                  path="/inventory/current-stock-report"
                  component={InventoryContainer}
                />


<Route exact path="/egov" component={EgovernanceContainer} />
                <Route
                  exact
                  path="/egov/search"
                  component={EGovernanceSearchContainer}
                />
                <Route
                  exact
                  path="/egov/advance"
                  component={EgovernanceAdvanceContainer}
                />

            <ProtectedRoutes pp={this.props} />
          </Switch>

          <LargeAlert />
        </div>

      </Suspense>
    );
  }
}

export default App;

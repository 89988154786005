import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import AuthService from '../auth_components/js/AuthService';
import ApiReport from '../Components/ApiReport/ApiReport';
import { Route, Switch } from 'react-router-dom';
import ApiReportAll from '../Components/ApiReport/ApiReportAll';
import ApiReportPublication from '../Components/ApiReport/ApiReportPublication';

class ApiReportContainer extends Component {
    constructor(props) {
        super(props);

        let Auth = new AuthService;

        let user = Auth.getUser();
        let username = user.email;
        let name = user.name;

        this.state = {
            username: username,
            apikey: user.apikey,
            name: name,
            user: user
        }

    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} username={this.state.username} apikey={this.state.apikey} />
                <div className="main-body-no-padding">
                    

                                <Switch>
                            <Route exact path="/apireport"
                                render={() => <ApiReport
                                    history={this.props.history}
                                    username={this.state.username}
                                    name={this.state.name}
                                    user={this.state.user}
                                    apikey={this.state.apikey} public={false} />} 
                                    
                            />

                                <Route exact path="/apireportall"
                                render={() => <ApiReportAll
                                    history={this.props.history}
                                    username={this.state.username}
                                    name={this.state.name}
                                    user={this.state.user}
                                    apikey={this.state.apikey} />} 
                                />


                            <Route exact path="/apireportpublication"
                                render={() => <ApiReportPublication
                                    history={this.props.history}
                                    username={this.state.username}
                                    name={this.state.name}
                                    user={this.state.user}
                                    public={false}
                                    apikey={this.state.apikey} />} 
                                />

                               

                            </Switch>
                </div>
            </div>
        )
    }
}

export default withAuth(ApiReportContainer);
import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "scroll",
  },
});

class BooksPublished extends Component {
  state = {
    title: "",
    type: "",
    publisher_issn: "",
    peer: "",
    no_co_au: "",
    main_au: "",
    institution: "",
    api_score: "",
    year: "",
    books_published: [],
  };

  componentDidMount() {
    //console.log(this.props)
    this.setState({
      books_published: this.props.books_published,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        books_published: this.props.books_published,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeNew = (name, value, index, array) => {
    let data = this.state[array];
    data[index][name] = value;

    this.setState({
      [array]: data,
    });
  };

  onSubmit = () => {
    let d = {
      title: this.state.title,
      type: this.state.type,
      publisher_issn: this.state.publisher_issn,
      peer: this.state.peer,
      no_co_au: this.state.no_co_au,
      main_au: this.state.main_au,
      institution: this.state.institution,
      api_score: this.state.api_score,
      year: this.state.year,
    };

    let books_published = this.state.books_published;
    books_published.push(d);
    this.setState(
      {
        books_published,
      },
      () => {
        this.props.setBooksPublished(books_published);
        this.setState({
          title: "",
          type: "",
          publisher_issn: "",
          peer: "",
          no_co_au: "",
          main_au: "",
          institution: "",
          api_score: "",
          year: "",
        });
      }
    );
  };

  onDelete = (index) => {
    let books_published = this.state.books_published;
    books_published.splice(index, 1);
    this.props.setBooksPublished(books_published);
    this.setState({
      books_published,
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <table width="100%" className="table">
          <thead>
            <tr>
              <td width="20%">Name of Book and year published</td>
              <td width="10%">Type of Book & Authorship</td>

              <td width="15%">Publisher & ISSN/ ISBN No.</td>
              <td width="10%">Whether Peer Reviewed</td>

              <td width="8%">No. of Co-author</td>
              <td width="5%">Whether you are the main author</td>
              <td width="5%">
                Whether at the time of publication Affiliating Institution was
                same Yes/ No
              </td>
              <td width="5%">API Score</td>
              <td width="10%">Year</td>
              <td>Click + to Add</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="20%">
                <textarea
                  className="form-control"
                  type="text"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  placeholder="Name of Book
                                    and year published"
                />
              </td>
              <td width="10%">
                <textarea
                  className="form-control"
                  type="text"
                  name="type"
                  value={this.state.type}
                  onChange={this.onChange}
                  placeholder="Type of Book &
                                    Authorship"
                />
              </td>
              <td width="20%">
                <textarea
                  className="form-control"
                  type="text"
                  name="publisher_issn"
                  value={this.state.publisher_issn}
                  onChange={this.onChange}
                  placeholder="Publisher &
                                    ISSN/ ISBN No."
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="peer"
                  value={this.state.peer}
                  onChange={this.onChange}
                  placeholder="Whether Peer Reviewed"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="no_co_au"
                  value={this.state.no_co_au}
                  onChange={this.onChange}
                  placeholder="No. of 
                                    Co-author"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="main_au"
                  value={this.state.main_au}
                  onChange={this.onChange}
                  placeholder="Whether you are the main author"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="institution"
                  value={this.state.institution}
                  onChange={this.onChange}
                  placeholder="Yes/No"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="api_score"
                  value={this.state.api_score}
                  onChange={this.onChange}
                  placeholder="API Score"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="year"
                  value={this.state.year}
                  onChange={this.onChange}
                  placeholder="Year"
                />
              </td>

              <td>
                <Button
                  variant="outlined"
                  onClick={this.onSubmit}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {this.state.books_published.map((el, index) => (
              <tr key={index}>
                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="title"
                    value={el.title}
                    onChange={(e) =>
                      this.onChangeNew(
                        "title",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="Name of Book and year published	"
                  />
                </td>
                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="type"
                    value={el.type}
                    onChange={(e) =>
                      this.onChangeNew(
                        "type",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="Type of Book & Authorship	"
                  />
                </td>

                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="publisher_issn"
                    value={el.publisher_issn}
                    onChange={(e) =>
                      this.onChangeNew(
                        "publisher_issn",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="Publisher & ISSN/ ISBN No.	"
                  />
                </td>
                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="peer"
                    value={el.peer}
                    onChange={(e) =>
                      this.onChangeNew(
                        "peer",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="Whether Peer Reviewed	"
                  />
                </td>
                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="no_co_au"
                    value={el.no_co_au}
                    onChange={(e) =>
                      this.onChangeNew(
                        "no_co_au",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="No. of Co-author	"
                  />
                </td>
                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="main_au"
                    value={el.main_au}
                    onChange={(e) =>
                      this.onChangeNew(
                        "main_au",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="Whether you are the main author	"
                  />
                </td>
                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="institution"
                    value={el.institution}
                    onChange={(e) =>
                      this.onChangeNew(
                        "institution",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="Yes/No"
                  />
                </td>

                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="api_score"
                    value={el.api_score}
                    onChange={(e) =>
                      this.onChangeNew(
                        "api_score",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="API Score"
                  />
                </td>

                <td>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="year"
                    value={el.year}
                    onChange={(e) =>
                      this.onChangeNew(
                        "year",
                        e.target.value,
                        index,
                        "books_published"
                      )
                    }
                    placeholder="Year"
                  />
                </td>

                <td>
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(styles)(BooksPublished);

import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class ReadingMaterial extends Component {
    state = {
        course: '',
        consulted: '',
        prescribed: '',
        resource:'',
       reading_material: [],
       

    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            reading_material:this.props.reading_material,
            
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                reading_material:this.props.reading_material,
              

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            course: this.state.course,
            consulted: this.state.consulted,
            prescribed: this.state.prescribed,
            resource: this.state.resource,
            
        }

        let reading_material = this.state.reading_material;
        reading_material.push(d);
        this.setState({
            reading_material
        }, () => {
                this.props.setReadingMaterial(reading_material);
                this.setState({
                    course: '',
                    consulted: '',
                    prescribed: '',
                    resource:'',
                })
        })
    }

    onDelete = (index) => {
        let reading_material = this.state.reading_material;
        reading_material.splice(index,1);
        this.props.setReadingMaterial(reading_material);
        this.setState({
            reading_material
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr >
                            <td >Course/Paper</td>
                            <td >Consulted</td>
                           
                            <td >Prescribed</td>
                                        
                           
                            <td >Additional
                                resource
                                provided
                                </td>
                                    
                                    
                            <td >Click + to Add</td>
                        </tr>
                       
                    </thead>
                    <tbody>
                        <tr>
                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="course"
                                    value={this.state.course}
                                    onChange={this.onChange}
                                    placeholder="Course/Paper"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="consulted"
                                    value={this.state.consulted}
                                    onChange={this.onChange}
                                    placeholder="consulted"
                                />
                            </td>
                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="prescribed"
                                    value={this.state.prescribed}
                                    onChange={this.onChange}
                                    placeholder="Prescribed"
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="resource"
                                    value={this.state.resource}
                                    onChange={this.onChange}
                                    placeholder="Additional rource provided"
                                />
                            </td>
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.reading_material.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.course}</td>
                                <td>{el.consulted}</td>
                                <td>{el.prescribed}</td>
                                <td>{el.resource}</td>                                
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                         {this.state.reading_material.length>0 && <tr>
                            <td colSpan={4}>
                                <input
                                    readOnly
                                    className="form-control"
                                    type="text"
                                    name="reading_material_text"
                                    value={this.props.reading_material_text}
                                    
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="reading_material_score"
                                    value={this.props.reading_material_score}
                                    onChange={(e)=>this.props.setRead(e.target.value)}
                                    placeholder="API Score"
                                />
                            </td>
                            </tr>}
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(ReadingMaterial)
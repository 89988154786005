import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class PreviousAppointment extends Component {
    state = {
        designation: '',
        employer: '',
        joining_date: '',
        leaving_date:'',
        salary: '',
        reason:'',
        last_appointment: []
    }

    componentDidMount(){
        this.setState({
            last_appointment:this.props.last_appointment
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                last_appointment:this.props.last_appointment
          
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            designation: this.state.designation,
            employer: this.state.employer,
            joining_date: this.state.joining_date,
            leaving_date: this.state.leaving_date,
            salary: this.state.salary,
            reason: this.state.reason,

        }

        let last_appointment = this.state.last_appointment;
        last_appointment.push(d);
        this.setState({
            last_appointment
        }, () => {
                this.props.setLastAppointment(last_appointment);
                this.setState({
                    designation: '',
                    employer: '',
                    joining_date: '',
                    leaving_date:'',
                    salary: '',
                    reason:'',
                })
        })
    }

    onDelete = (index) => {
        let last_appointment = this.state.last_appointment;
        last_appointment.splice(index,1);
        this.props.setLastAppointment(last_appointment);

        this.setState({
            last_appointment
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td>Designation</td>
                            <td>Name of Employer</td>
                            <td>Date of Joining</td>
                            <td>Date of Leaving</td>

                            <td>Salary with Grade Pay</td>
                            <td>Reason of Leaving</td>
                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="designation"
                                    value={this.state.designation}
                                    onChange={this.onChange}
                                    placeholder="Designation"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="employer"
                                    value={this.state.employer}
                                    onChange={this.onChange}
                                    placeholder="Employer"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="joining_date"
                                    value={this.state.joining_date}
                                    onChange={this.onChange}
                                    placeholder="Date of Joining"
                                />
                            </td>
                            
                            <td>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="leaving_date"
                                    value={this.state.leaving_date}
                                    onChange={this.onChange}
                                    placeholder="Date of Leaving"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="salary"
                                    value={this.state.salary}
                                    onChange={this.onChange}
                                    placeholder="Salary"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="reason"
                                    value={this.state.reason}
                                    onChange={this.onChange}
                                    placeholder="Reason"
                                />
                            </td>
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.last_appointment.map((el, index) =>

                            <tr key={index}>
                                <td>{el.designation}</td>
                                <td>{el.employer}</td>
                                <td>{el.joining_date}</td>
                                <td>{el.leaving_date}</td>
                                <td>{el.salary}</td>
                                <td>{el.reason}</td>
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(PreviousAppointment)
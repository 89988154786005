import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "scroll",
  },
});

class FullPapers extends Component {
  state = {
    title: "",
    details: "",
    issn: "",

    no_co_au: "",
    main_au: "",
    institution: "",
    api_score: "",
    year: "",
    full_papers: [],
  };

  componentDidMount() {
    //console.log(this.props)
    this.setState({
      full_papers: this.props.full_papers,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        full_papers: this.props.full_papers,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeNew = (name, value, index, array) => {
    let data = this.state[array];
    data[index][name] = value;

    this.setState({
      [array]: data,
    });
  };

  onSubmit = () => {
    let d = {
      title: this.state.title,
      details: this.state.details,
      issn: this.state.issn,
      no_co_au: this.state.no_co_au,
      main_au: this.state.main_au,
      institution: this.state.institution,
      api_score: this.state.api_score,
      year: this.state.year,
    };

    let full_papers = this.state.full_papers;
    full_papers.push(d);
    this.setState(
      {
        full_papers,
      },
      () => {
        this.props.setFullPapers(full_papers);
        this.setState({
          title: "",
          details: "",
          issn: "",
          no_co_au: "",
          main_au: "",
          institution: "",

          api_score: "",
          year: "",
        });
      }
    );
  };

  onDelete = (index) => {
    let full_papers = this.state.full_papers;
    full_papers.splice(index, 1);
    this.props.setFullPapers(full_papers);
    this.setState({
      full_papers,
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <table width="100%" className="table">
          <thead>
            <tr>
              <td width="20%">Title with Page Numbers</td>
              <td width="20%">Title, editor & publisher and Year</td>

              <td width="10%">ISSN/ ISBN No.</td>

              <td width="5%">No. of Co-author</td>
              <td width="5%">Whether you are the main author</td>
              <td width="5%">
                Whether at the time of publication Affiliating Institution was
                same Yes/ No
              </td>
              <td width="5%">API Score</td>
              <td width="10%">Year</td>
              <td width="5%">Click + to Add</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="20%">
                <textarea
                  className="form-control"
                  type="text"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  placeholder="Title with Page Numbers"
                />
              </td>
              <td width="35%">
                <textarea
                  className="form-control"
                  type="text"
                  name="details"
                  value={this.state.details}
                  onChange={this.onChange}
                  placeholder="Title, editor & publisher and Year"
                />
              </td>
              <td width="15%">
                <textarea
                  className="form-control"
                  type="text"
                  name="issn"
                  value={this.state.issn}
                  onChange={this.onChange}
                  placeholder="ISSN/ ISBN No"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="no_co_au"
                  value={this.state.no_co_au}
                  onChange={this.onChange}
                  placeholder="No. of 
                                    Co-author"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="main_au"
                  value={this.state.main_au}
                  onChange={this.onChange}
                  placeholder="Whether you are the main author"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="institution"
                  value={this.state.institution}
                  onChange={this.onChange}
                  placeholder="Yes/No"
                />
              </td>

              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="api_score"
                  value={this.state.api_score}
                  onChange={this.onChange}
                  placeholder="API Score"
                />
              </td>
              <td>
                <textarea
                  className="form-control"
                  type="text"
                  name="year"
                  value={this.state.year}
                  onChange={this.onChange}
                  placeholder="Year"
                />
              </td>

              <td>
                <Button
                  variant="outlined"
                  onClick={this.onSubmit}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {this.state.full_papers.map((el, index) => (
              <tr key={index}>
                <td style={{padding: 0}}>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="title"
                    value={el.title}
                    onChange={(e) =>
                      this.onChangeNew(
                        "title",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="Title with Page Numbers"
                  />
                </td>
                <td style={{padding: 0}}>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="details"
                    value={el.details}
                    onChange={(e) =>
                      this.onChangeNew(
                        "details",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="Title, editor & publisher and Year	"
                  />
                </td>
                <td style={{padding: 0}}>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="issn"
                    value={el.issn}
                    onChange={(e) =>
                      this.onChangeNew(
                        "issn",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="ISSN"
                  />
                </td>

                <td style={{padding: 0}}>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="no_co_au"
                    value={el.no_co_au}
                    onChange={(e) =>
                      this.onChangeNew(
                        "no_co_au",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="No. of Co-author	"
                  />
                </td>

                <td style={{padding: 0}}>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="main_au"
                    value={el.main_au}
                    onChange={(e) =>
                      this.onChangeNew(
                        "main_au",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="Whether you are the main author	"
                  />
                </td>

                <td style={{padding: 0}}>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="institution"
                    value={el.institution}
                    onChange={(e) =>
                      this.onChangeNew(
                        "institution",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="Whether at the time of publication Affiliating Institution was same Yes/ No"
                  />
                </td>
                <td style={{padding: 0}}>
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="api_score"
                    value={el.api_score}
                    onChange={(e) =>
                      this.onChangeNew(
                        "api_score",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="API Score"
                  />
                </td>
                <td style={{padding: 0}}>
                  {" "}
                  <textarea
                    className="form-control"
                    type="text"
                    rows="5"
                    style={{ width: "100%" }}
                    name="year"
                    value={el.year}
                    onChange={(e) =>
                      this.onChangeNew(
                        "year",
                        e.target.value,
                        index,
                        "full_papers"
                      )
                    }
                    placeholder="Year"
                  />
                </td>

                <td>
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(styles)(FullPapers);

import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class PostAppointment extends Component {
    state = {
        designation: '',
        department: '',
        joining_date: '',
        leaving_date:'Till date',
        grade: '',
       
        post_appointment: []
    }

    componentDidMount(){
        this.setState({
            post_appointment:this.props.post_appointment
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                post_appointment:this.props.post_appointment
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            designation: this.state.designation,
            department: this.state.department,
            joining_date: this.state.joining_date,
            leaving_date: this.state.leaving_date,
            grade: this.state.grade,

        }

        let post_appointment = this.state.post_appointment;
        post_appointment.push(d);
        this.setState({
            post_appointment
        }, () => {
                this.props.setPostAppointment(post_appointment);
                this.setState({
                    designation: '',
                    department: '',
                    joining_date: '',
                    leaving_date:'',
                    grade: '',
                })
        })
    }

    onDelete = (index) => {
        let post_appointment = this.state.post_appointment;
        post_appointment.splice(index,1);
        this.props.setPostAppointment(post_appointment);

        this.setState({
            post_appointment
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td>Designation</td>
                            <td>Department</td>
                            <td>From</td>
                            <td>To</td>

                            <td>Grade</td>
                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="designation"
                                    value={this.state.designation}
                                    onChange={this.onChange}
                                    placeholder="Designation"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="department"
                                    value={this.state.department}
                                    onChange={this.onChange}
                                    placeholder="Department"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="joining_date"
                                    value={this.state.joining_date}
                                    onChange={this.onChange}
                                    placeholder="Date of Joining"
                                />
                            </td>
                            
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="leaving_date"
                                    value={this.state.leaving_date}
                                    onChange={this.onChange}
                                    placeholder="Date of Leaving"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="grade"
                                    value={this.state.grade}
                                    onChange={this.onChange}
                                    placeholder="Grade"
                                />
                            </td>
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.post_appointment.map((el, index) =>

                            <tr key={index}>
                                <td>{el.designation}</td>
                                <td>{el.department}</td>
                                <td>{el.joining_date}</td>
                                <td>{el.leaving_date}</td>
                                <td>{el.grade}</td>
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(PostAppointment)
import React, { Component } from "react";
import {
  withStyles,
  Button,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { GetData } from "../../../api/service";
import ReactToPrint from "react-to-print";
import "./formprint.css";
import Barcode from "react-barcode";
import Loading from "../Forms/Loading";
import AlertDiv from "../Forms/AlertDiv";
import { orange } from "@material-ui/core/colors";
import { blue } from "@material-ui/core/colors";

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});

class ViewForm extends Component {
  state = {
    isLoaded: false,
    step: 7,
    data: [],
  };

  componentDidMount() {
    let data = this.props.data;
    //console.log(data);

    if (Array.isArray(data) && data.length > 0) {
      this.setState({
        data: this.props.data,
        isLoaded: true,
      });
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <AlertDiv back={orange[100]} border={orange[400]}>
            You can print this form or can save as pdf. Click on the button
            below
          </AlertDiv>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print or Save
              </Button>
            )}
            ref={(el) => (this.buttonRef = el)}
            content={() => this.componentRef}
            pageStyle={{ margin: "10px" }}
            copyStyles={true}
          />
          &nbsp;{" "}
          <Button
            size="small"
            color="secondary"
            variant="contained"
            type="button"
            onClick={this.props.back}
          >
            Previous
          </Button>
        </div>

        {this.state.isLoaded ? (
          <PreviewForm
            ref={(el) => (this.componentRef = el)}
            data={this.state.data}
            classes={classes}
            back={this.props.back}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(ViewForm);

class PreviewForm extends Component {
  state = {
    isLoaded: false,
    step: 5,
  };

  componentDidMount() {
    let data = this.props.data;
    //console.log(data);

    if (Array.isArray(data) && data.length > 0) {
      this.setState({
        ...data[0],
        ...data[0].present_address,
        ...data[0].permanent_address,
        isLoaded: true,
      });
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <div className="admission-form">
        <br />
        {this.state.isLoaded && (
          <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div style={{ overflowX: "scroll" }}>
                    <table width="100%" border="solid">
                      <tbody>
                        <tr>
                          <td colSpan={4} align="center">
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              NAME OF THE INSTITUTION: B.H. COLLEGE, HOWLY
                              <br />
                              PBAS Proforma for Promotion under CAS
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              1. Name : {this.state.name}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              2. Father's Name : {this.state.father_name}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              3. Department : {this.state.dept_name}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              4. Educational Qualification :{" "}
                              {this.state.h_edu_qua}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              5. Current Designation and Grade Pay :{" "}
                              {this.state.c_d_g_p}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              6. Date of Last Promotion :{" "}
                              {this.state.date_of_l_p}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              7. Date and Place of Birth :{" "}
                              {this.state.date_of_birth} ,{" "}
                              {this.state.place_of_birth}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              8. Gender : {this.state.gender}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              9. Marrital Status : {this.state.marrital_status}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              10. Nationality : {this.state.nationality}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              11. Caste : {this.state.caste}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              12. Address for Correspondence (With PIN Code) :{" "}
                              <br />
                              Area/Village/Town: {this.state.pre_village}
                              <br />
                              P.O: {this.state.pre_po}
                              <br />
                              Dist: {this.state.pre_dist}, State:{" "}
                              {this.state.pre_state}
                              <br />
                              Pin: {this.state.pre_pin}
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              13. Permanent Address (With PIN Code) : <br />
                              Village/Town: {this.state.per_village}
                              <br />
                              P.O: {this.state.per_po}
                              <br />
                              Dist: {this.state.per_dist}, State:{" "}
                              {this.state.per_state}
                              <br />
                              Pin: {this.state.per_pin}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <table width="100%" border="solid">
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              14. Academic Qualification (HSLC till Post
                              Graduation):
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Examination
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Name of Board/ University
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Year of Passing
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Division/ Class/ Grade
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Subjects
                            </Typography>
                          </td>
                        </tr>

                        {this.state.last_exam.map((el) => (
                          <tr>
                            <td>{el.examination_passed}</td>
                            <td>{el.board}</td>
                            <td>{el.year}</td>
                            <td>{el.division}</td>
                            <td>{el.subjects}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              15. Research Degree (s) :{" "}
                              {this.state.research_degree}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <table width="100%" border="solid">
                      <tbody>
                        <tr>
                          <td colSpan={6}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              16. Appointment held prior to joining this
                              Institution:{" "}
                              {this.state.last_appointment.length == 0
                                ? "NIL"
                                : ""}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Designation
                            </Typography>
                          </td>

                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Name of Employer
                            </Typography>
                          </td>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Date of
                            </Typography>
                          </td>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Salary with Grade Pay
                            </Typography>
                          </td>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Reason of Leaving
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Joining
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Leaving
                            </Typography>
                          </td>
                        </tr>

                        {this.state.last_appointment.map((el, index) => (
                          <tr key={index}>
                            <td>{el.designation}</td>
                            <td>{el.employer}</td>
                            <td>{el.joining_date}</td>
                            <td>{el.leaving_date}</td>
                            <td>{el.salary}</td>
                            <td>{el.reason}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <br />
                    <table width="100%" border="solid">
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              17. Post held after appointment at this
                              Institution::
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Designation
                            </Typography>
                          </td>

                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Department
                            </Typography>
                          </td>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Date of Actual Joining
                            </Typography>
                          </td>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Grade
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              From
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              To
                            </Typography>
                          </td>
                        </tr>

                        {this.state.post_appointment.map((el, index) => (
                          <tr key={index}>
                            <td>{el.designation}</td>
                            <td>{el.department}</td>
                            <td>{el.joining_date}</td>
                            <td>{el.leaving_date}</td>
                            <td>{el.grade}</td>
                          </tr>
                        ))}

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              18. Period of Teaching Experience:
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              P.G. Classes (In Years): {this.state.p_of_t_PG}
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              U.G. Classes (In Years): {this.state.p_of_t_UG}
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              19. Research Experience Excluding Years Spent in
                              M.Phil/PhD (In Years):{" "}
                              {this.state.research_experience}
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              20. Field of Specialization under the
                              Subject/Discipline (if any):Energy and Environment
                              *Common Property Resources * Rural and Community
                              Development *Irrigation and Water Management *
                              Livelihood : {this.state.f_of_specialisation}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <table width="100%" border="solid">
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              21. Academic Staff College Orientation/ Refresher
                              Course/ Summer School/ Any Other Course Attended :
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Name of Course
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Place
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Duration
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sponsoring Agency
                            </Typography>
                          </td>
                        </tr>

                        {this.state.other_course_attended.map((el) => (
                          <tr>
                            <td>{el.course}</td>
                            <td>{el.place}</td>
                            <td>{el.duration}</td>
                            <td>{el.sponsor}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <br />
                    <table width="100%" border="solid">
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              22. CATEGORY II: CO-CURRICULAR, EXTENSION,
                              PROFESSIONAL DEVELOPMENT RELATED ACTIVITIES (From
                              28-06-2010 to 27-06-2015) :
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Type of Activities
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Period
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API claimed
                            </Typography>
                          </td>
                        </tr>

                        {this.state.activities.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.activity_name}</td>
                            <td>{el.period}</td>
                            <td>{el.api_claimed}</td>
                          </tr>
                        ))}

                        {this.state.activities.length > 0 && (
                          <tr>
                            <td>&nbsp;</td>
                            <td colSpan={2} align="right">
                              Total Score
                            </td>
                            <td align="left">{this.state.activity_total}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <br />
                    <table width="100%" border="solid">
                      <tbody>
                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              23. CATEGORY III: RESEARCH, PUBLICATIONS AND
                              ACADEMIC CONTRIBUTIONS :
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              A) (i) Published Papers in Journals:
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title with Page Numbers
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Journal
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              ISSN/ ISBN No.
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether Peer Reviewed Impact Factor, if any
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              No. of Co-author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether you are the main author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Year
                            </Typography>
                          </td>
                        </tr>

                        {this.state.journals.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.title}</td>
                            <td>{el.journal}</td>
                            <td>{el.issn}</td>
                            <td>{el.impact_factor}</td>
                            <td>{el.no_co_au}</td>
                            <td>{el.main_au}</td>
                            <td>{el.api_score}</td>
                            <td>{el.year}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              ii) Published Paper in Journals notified on UGC
                              website :
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title of the paper
                            </Typography>
                          </td>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Name of the journal
                            </Typography>
                          </td>

                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Year of Publication
                            </Typography>
                          </td>

                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              ISSN No.
                            </Typography>
                          </td>

                          <td colSpan={4}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Link to the recognition in UGC enlistment of the
                              Journal/ Digital Object Identifier (doi) No.
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Link to Website of the Journal
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Link to article/ paper/ abstract of the article
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Is it listed in UGC Carelist /Scopus/ Web of
                              Science / Other, Mention
                            </Typography>
                          </td>
                        </tr>

                        {this.state.journal_paper.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.title}</td>
                            <td>{el.journal}</td>
                            <td>{el.year}</td>
                            <td>{el.issn}</td>
                            <td>{el.website_link}</td>
                            <td>{el.article_link}</td>
                            <td colSpan={2}>
                              {el.listed_main} &nbsp;
                              {el.listed}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              B) (i) Articles/Chapters published in Books:
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title with Page Numbers
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Book Title, editor & publisher and Year
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              ISSN/ ISBN No.
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether Peer Reviewed
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              No. of Co-author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether you are the main author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether at the time of publication Affiliating
                              Institution was same Yes/ No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Year
                            </Typography>
                          </td>
                        </tr>

                        {this.state.articles.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.title}</td>
                            <td>{el.publisher}</td>
                            <td>{el.issn}</td>
                            <td>{el.impact_factor}</td>
                            <td>{el.no_co_au}</td>
                            <td>{el.main_au}</td>
                            <td>{el.institution}</td>
                            <td>{el.api_score}</td>

                            <td>{el.year}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={8}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              B) (ii) Full Papers in Conference Proceedings:
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title with Page Numbers
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title, editor & publisher and Year
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              ISSN/ ISBN No.
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              No. of Co-author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether you are the main author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether at the time of publication Affiliating
                              Institution was same Yes/ No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Year
                            </Typography>
                          </td>
                        </tr>

                        {this.state.full_papers.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={2}>{el.title}</td>
                            <td>{el.details}</td>
                            <td>{el.issn}</td>

                            <td>{el.no_co_au}</td>
                            <td>{el.main_au}</td>
                            <td>{el.institution}</td>
                            <td>{el.api_score}</td>
                            <td>{el.year}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={8}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              B) (iii) Books Published as Single or as Editor :
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Name of Book and year published
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Type of Book & Authorship
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Publisher & ISSN/ ISBN No.
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether Peer Reviewed
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              No. of Co-author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether you are the main author
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether at the time of publication Affiliating
                              Institution was same Yes/ No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Year
                            </Typography>
                          </td>
                        </tr>

                        {this.state.books_published.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.title}</td>
                            <td>{el.type}</td>
                            <td>{el.publisher_issn}</td>
                            <td>{el.peer}</td>
                            <td>{el.no_co_au}</td>
                            <td>{el.main_au}</td>
                            <td>{el.institution}</td>
                            <td>{el.api_score}</td>
                            <td>{el.year}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={8}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              (iv) Ongoing and completed Research Project and
                              Consultancies:
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              C) I & II) Ongoing Projects/Consultancies:
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title
                            </Typography>
                          </td>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Agency
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Period
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Grant/ Amount Mobilized (Rs. Lakh)
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.on_going_projects.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={2}>{el.title}</td>
                            <td colSpan={2}>{el.agency}</td>
                            <td>{el.period}</td>
                            <td>{el.grant}</td>

                            <td colSpan={2}>{el.api_score}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              C) ((iii & iv) Completed Projects/Consultancies:
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Agency
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Period
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Grant/ Amount Mobilized (Rs. Lakh)
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether policy document/ patent as outcome
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.completed_projects.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={2}>{el.title}</td>
                            <td>{el.agency}</td>
                            <td>{el.period}</td>
                            <td>{el.grant}</td>
                            <td>{el.document}</td>

                            <td colSpan={2}>{el.api_score}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              D) Research Guidance:
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td rowSpan={2} colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Level of Guidance
                            </Typography>
                          </td>
                          <td rowSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Number Enrolled
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Thesis/Dissertation submitted
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Degree Awarded
                            </Typography>
                          </td>

                          <td rowSpan={2} colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Score
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Period
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Number
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Period
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Number
                            </Typography>
                          </td>
                        </tr>

                        {this.state.research_guidance.map((el, index) => (
                          <tr key={index}>
                            <td colSpan={2}>{el.level}</td>

                            <td>{el.number}</td>
                            <td>{el.th_period}</td>
                            <td>{el.th_number}</td>

                            <td>{el.d_period}</td>
                            <td>{el.d_number}</td>

                            <td colSpan={2}>{el.score}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              E) (i) Training Courses,
                              Teaching-Learning-Evaluation Technology
                              Programmes, Faculty Development Programmes (Not
                              less than one week duration):
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Programme
                            </Typography>
                          </td>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Duration
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Organized by
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.training_courses.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={2}>{el.programme}</td>
                            <td colSpan={2}>{el.duration}</td>
                            <td colSpan={2}>{el.orgainised_by}</td>

                            <td colSpan={2}>{el.api_score}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              E) (ii) Papers presented in Conferences, Seminars,
                              Workshop, Symposia
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title of the paper presented
                            </Typography>
                          </td>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title of Conference Seminar
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Organized by
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether international/ national/ state/ regional/
                              college or university
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.papers_presented.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={2}>{el.paper}</td>
                            <td colSpan={2}>{el.conference}</td>
                            <td>{el.orgainised_by}</td>
                            <td>{el.type}</td>

                            <td colSpan={2}>{el.api_score}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              E) (iii) Invited Lectures and Chairmanships at
                              national or international conference/seminar etc.:
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title of Lecture/ Academic Session
                            </Typography>
                          </td>
                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Title of Conference/ Seminar
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Organized by
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Whether international/ national/ state/ regional/
                              college or university
                            </Typography>
                          </td>

                          <td colSpan={2}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.invited_lectures.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={2}>{el.paper}</td>
                            <td colSpan={2}>{el.conference}</td>
                            <td>{el.orgainised_by}</td>
                            <td>{el.type}</td>

                            <td colSpan={2}>{el.api_score}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              PART B : ACADEMIC PERFORMANCE INDICATOR ( Please
                              see details instructions of this PBAS proformas
                              before filling out this section)
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              CATEGORY I : TEACHING, LEARNING AND EVALUATION
                              RELATED ACTIVITIES
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              (i) Lectures, Seminars, Tutorials, Practical,
                              Contact Hours (give semester-wise details, where
                              necessary) :
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Course
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Level
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Mode of teaching
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Hours per week alloted
                            </Typography>
                          </td>

                          <td colSpan={4}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              % of classes taken as per documented record
                            </Typography>
                          </td>
                        </tr>

                        {this.state.teaching_learning.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.course}</td>
                            <td>{el.level}</td>
                            <td>{el.mode}</td>
                            <td>{el.hour}</td>

                            <td colSpan={4}>{el.percentage}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Lecture(L), Seminar(S), Tutorial(T), Practical(P),
                              Contact Hours(C)
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={7}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              &nbsp;
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.teaching_learning_score.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={7}>{el.class}</td>
                            <td>{el.class_api}</td>
                          </tr>
                        ))}

                        {this.state.teaching_learning_score.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 2}</td>
                            <td colSpan={7}>{el.load}</td>
                            <td>{el.load_api}</td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              (ii) Reading/Instructional materials consulted and
                              additional knowledge resources provided to
                              students :
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Course/Paper
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Consulted
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Prescribed
                            </Typography>
                          </td>

                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Additional resource provided
                            </Typography>
                          </td>
                        </tr>

                        {this.state.reading_material.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.course}</td>
                            <td>{el.consulted}</td>
                            <td>{el.prescribed}</td>
                            <td colSpan={5}>{el.resource}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colspan={8}>
                            API score based on Preparation and imparting of
                            knowledge/instruction as per curriculum & syllabus
                            enrichment by providing additional resources to
                            students
                          </td>
                          <td>{this.state.reading_material_score}</td>
                        </tr>

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              (iii) Use of participatory and innovative
                              Teaching-Learning Methodologies/Audio-visual
                              teaching aid, Updating of subject Content, Course
                              Improvement etc. :
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={7}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Short Description
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.participation_innovation.map(
                          (el, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td colSpan={7}>{el.description}</td>
                              <td>{el.api_score}</td>
                            </tr>
                          )
                        )}

                        {this.state.participation_innovation.length > 0 && (
                          <tr>
                            <td colSpan={8} align="right">
                              Total Score
                            </td>
                            <td>
                              {this.state.participation_innovation.reduce(
                                (pre, curr) =>
                                  parseFloat(pre) + parseFloat(curr.api_score),
                                0
                              )}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              (iv) Examination Duties Assigned and Performed :
                            </Typography>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Sl.No
                            </Typography>
                          </td>

                          <td colSpan={5}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Type of Examination Duties
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Duties Assigned
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              Extend to which carried out (%)
                            </Typography>
                          </td>

                          <td>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              API Score
                            </Typography>
                          </td>
                        </tr>

                        {this.state.examination_duty.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td colSpan={5}>{el.type}</td>

                            <td>{el.duty}</td>

                            <td>{el.extend}</td>
                            <td>{el.api_score}</td>
                          </tr>
                        ))}

                        {this.state.examination_duty.length > 0 && (
                          <tr>
                            <td colSpan={8} align="right">
                              Total Score
                            </td>
                            <td>
                              {this.state.examination_duty.reduce(
                                (pre, curr) =>
                                  parseFloat(pre) + parseFloat(curr.api_score),
                                0
                              )}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan={9}>
                            <Typography
                              style={{
                                fontWeight: "18px",
                                fontWeight: 500,
                              }}
                            >
                              PART C: OTHER RELEVANT INFORMATION (Please give
                              details of any other credential, significant
                              contributions, awards received etc. not mentioned
                              earlier) : {this.state.other_info}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

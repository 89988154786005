import React, { Component } from 'react'
import { withStyles, Stepper, Step, StepButton } from '@material-ui/core';
import FormOne from './FormOne';
import FormTwo from './FormTwo';
import { PostData, GetData, PutData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FormThree from './FormThree';
import Notifications, { notify } from 'react-notify-toast';
import ViewForm from '../View/ViewForm';
import Loader from './Loader';
import FormFour from './FormFour';
import FormFive from './FormFive';
import PreviewForm from '../View/PreviewForm';
import FormSix from './FormSix';

const styles = theme => ({
    step: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
})

const steps = [
    {
        label: 'STEP I'
    },
    {
        label: 'STEP II'
    },
    {
        label: 'STEP III'
    },
    {
        label: 'STEP IV'
    },
    {
        label: 'STEP V'
    },
    {
        label: 'STEP VI'
    },
    // {
    //     label: 'PREVIEW FORM'
    // },
    {
        label: 'PRINT FORM'
    }
]

class FormsLanding extends Component {
    state = {
        active: 0,
        email: '',
        name: '',
        data: [],
        completed: [],
        edit: false,
        edit_id: '',
        isLoaded: false,
        isSubmitting: false
    }

    componentDidMount() {
       // console.log(this.props)
        this.getInitialData()
    }


    getInitialData = () => {
        GetData(`/${this.props.user.email}/getemployeeforprofile`)
            .then((resp) => {
                //console.log(resp)
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp,
                        
                        edit: true,
                        email:this.props.user.email,
                        edit_id: resp[0].id,
                        active: resp[0].active_step,
                        isLoaded: true
                    })
                } else {
                    this.setState({
                        isLoaded: true,
                        active: 0
                    })
                }

            })
    }

    onSetData = (data) => {
        //console.log(data);
       
            this.setState({isSubmitting: true})
            PutData(`/${this.state.email}/updateemployeeprofile`, data)
                .then((resp) => {
                    this.setState({isSubmitting: false})
                    if (resp.status === true) {

                        //place this step to completed

                        this.getInitialData();
                       
                    } else {
                        confirmAlert({
                            title: 'Failed!!',
                            message: resp.message,
                            buttons: [
                                {
                                    label: 'Reload',
                                    //onClick: () => window.location.reload()
                                }
                            ]
                        });
                    }
                }).catch((err) => {
                    this.setState({isSubmitting: false})
                })
        
    }


   

    proceedToNext = () => {
        //get the next step
        let next_step = 0;
        if (this.state.active == 0) {
            next_step = 1;
        } else if (this.state.active == 1) {
            next_step = 2;
        } else if (this.state.active == 2) {
            next_step = 3;
        }
        else if (this.state.active == 3) {
            next_step = 4;
        }
        else if (this.state.active == 4) {
            next_step = 5;
        }
        else if (this.state.active == 5) {
            next_step = 6;
        }

        this.setState({
            active: next_step
        })
    }

    proceedToBack = () => {
        //get the next step
        let next_step = 0;
        if (this.state.active == 0) {
            next_step = 0;
        } else if (this.state.active == 1) {
            next_step = 0;
        } else if (this.state.active == 2) {
            next_step = 1;
        }
      
        else if (this.state.active == 3) {
            next_step = 2;
        }
        else if (this.state.active == 4) {
            next_step = 3;
        }
        else if (this.state.active == 5) {
            next_step = 4;
        }
        else if (this.state.active == 6) {
            next_step = 5;
        }

        this.setState({
            active: next_step
        })
    }

    

    gotToStep = (step) => {
        this.setState({
            active: step
        })
    }

    

    render() {
        let { classes } = this.props;
        let i = [];

        if (this.state.active == 0) {
            i = <FormOne
                history={this.props.history}
                user={this.props.user}
                setData={this.onSetData.bind(this)}
                data={this.state.data}
                edit={this.state.edit}
                next={this.proceedToNext}
                back={this.proceedToBack}
            />
        }
        else if (this.state.active == 1) {
            i = <FormTwo
                history={this.props.history}
                user={this.props.user}
               
                setData={this.onSetData.bind(this)}
                data={this.state.data}
                edit={this.state.edit}
                next={this.proceedToNext}
                back={this.proceedToBack}
            />
        }  else if (this.state.active == 2) {
            i = <FormThree
            history={this.props.history}
            user={this.props.user}
           
            setData={this.onSetData.bind(this)}
            data={this.state.data}
            edit={this.state.edit}
            next={this.proceedToNext}
            back={this.proceedToBack}
            />
        } else if (this.state.active == 3) {
            i = <FormFour
            history={this.props.history}
            user={this.props.user}
           
            setData={this.onSetData.bind(this)}
            data={this.state.data}
            edit={this.state.edit}
            next={this.proceedToNext}
            back={this.proceedToBack}
            />
        } 
        else if (this.state.active == 4) {
            i = <FormFive
            history={this.props.history}
            user={this.props.user}
           
            setData={this.onSetData.bind(this)}
            data={this.state.data}
            edit={this.state.edit}
            next={this.proceedToNext}
            back={this.proceedToBack}
            />
        }
        else if (this.state.active == 5) {
            i = <FormSix
            history={this.props.history}
            user={this.props.user}
           
            setData={this.onSetData.bind(this)}
            data={this.state.data}
            edit={this.state.edit}
            next={this.proceedToNext}
            back={this.proceedToBack}
            />
        }
        // else if (this.state.active == 6) {
        //     i = <PreviewForm
        //     history={this.props.history}
        //     user={this.props.user}
           
        //     setData={this.onSetData.bind(this)}
        //     data={this.state.data}
        //     edit={this.state.edit}
        //     next={this.proceedToNext}
        //     back={this.proceedToBack}
        //     />
        // }
        else if (this.state.active == 6 || this.state.active == 7) {
            i = <ViewForm
            history={this.props.history}
            user={this.props.user}
           
            setData={this.onSetData.bind(this)}
            data={this.state.data}
            edit={this.state.edit}
            next={this.proceedToNext}
            back={this.proceedToBack}
            />
        }

        return (
            <div>

                {/* <Notifications /> */}

                <div className={classes.step}>

                        <Stepper nonLinear activeStep={this.state.active}>
                            {steps.map((el, index) =>

                                <Step key={index}>
                                    <StepButton
                                    onClick={() => this.gotToStep(index)}

                                    >
                                        {el.label}
                                    </StepButton>
                                </Step>

                            )}
                        </Stepper>

</div>

<br />

                {this.state.isLoaded ? i : null}
            </div>
        )
    }
}

export default withStyles(styles)(FormsLanding)
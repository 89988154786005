import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class TeachingLearningScore extends Component {
    state = {
        class: 'Classes Taken (max 50 for 100% performance & proportionate score upto 80% performance, below which no score can be given',
        class_api: '',
        load: 'Teaching Load in excess of UGC norm',
        load_api:'',
       teaching_learning_score: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            teaching_learning_score:this.props.teaching_learning_score,
            
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                teaching_learning_score:this.props.teaching_learning_score,
              

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            class: this.state.class,
            class_api: this.state.class_api,
            load: this.state.load,
            load_api: this.state.load_api,
            
        }

        let teaching_learning_score = this.state.teaching_learning_score;
        teaching_learning_score.push(d);
        this.setState({
            teaching_learning_score
        }, () => {
                this.props.setTeachingLearningScore(teaching_learning_score);
                this.setState({
                    class: '',
                    class_api: '',
                    load: '',
                    load_api:'',
                })
        })
    }

    onDelete = (index) => {
        let teaching_learning_score = this.state.teaching_learning_score;
        teaching_learning_score.splice(index,1);
        this.props.setTeachingLearningScore(teaching_learning_score);
        this.setState({
            teaching_learning_score
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr >
                            <td></td>
                            <td>API Score</td>
                            <td >Click + to Add</td>
                        </tr>
                       
                    </thead>
                    <tbody>
                        {this.state.teaching_learning_score.length==0 && <>
                         <tr>
                            <td width="70%">
                                <input
                                    readOnly
                                    className="form-control"
                                    type="text"
                                    name="class"
                                    value={this.state.class}
                                    onChange={this.onChange}
                                    placeholder="Course"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="class_api"
                                    value={this.state.class_api}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>
                            
                           <td>&nbsp;</td>
                            
                        </tr>

                        <tr>
                            <td >
                                <input
                                    readOnly
                                    className="form-control"
                                    type="text"
                                    name="load"
                                    value={this.state.load}
                                    onChange={this.onChange}
                                    placeholder="load"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="load_api"
                                    value={this.state.load_api}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>
                            
                           
                            {this.state.teaching_learning_score.length==0 && <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>}
                        </tr>
                        </>}
                    </tbody>
                    <tbody>
                        {this.state.teaching_learning_score.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.class}</td>
                                <td>{el.class_api}</td>
                                <td>&nbsp;</td>
                                
                                
                            </tr>

                        )}

                        {this.state.teaching_learning_score.map((el, index) =>

                        <tr key={index}>
                            <td>{index+2}. {el.load}</td>
                            <td>{el.load_api}</td>
                            
                            
                            
                            <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                        </tr>

                        )}
                         
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(TeachingLearningScore)
import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class ResearchGuidance extends Component {
    state = {
        level: '',
        number: '',
        th_period: '',
        th_number:'',
        d_period:'',
        d_number:'',
        score:'',
        total:'',
        research_guidance: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            research_guidance:this.props.research_guidance,
            total:this.props.total
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                research_guidance:this.props.research_guidance,
                total:this.props.total

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            level: this.state.level,
            number: this.state.number,
            th_period: this.state.th_period,
            th_number: this.state.th_number,
            d_period: this.state.d_period,
            d_number: this.state.d_number,
            score: this.state.score,
            
        }

        let research_guidance = this.state.research_guidance;
        research_guidance.push(d);
        this.setState({
            research_guidance
        }, () => {
                this.props.setResearchGuidance(research_guidance);
                this.setState({
                    level: '',
                    number: '',
                    th_period: '',
                    th_number:'',
                    d_period:'',
                    d_number:'',
                    score:'',
                })
        })
    }

    onDelete = (index) => {
        let research_guidance = this.state.research_guidance;
        research_guidance.splice(index,1);
        this.props.setResearchGuidance(research_guidance);
        this.setState({
            research_guidance
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr >
                            <td rowSpan={2}>Level of Guidance</td>
                            <td rowSpan={2}>Number Enrolled</td>
                           
                            <td colSpan={2} align="left">Thesis/Dissertation submitted</td>
                                        
                           
                            <td colSpan={2} align="left">Degree Awarded</td>
                                    
                                    <td rowSpan={2}>Score</td>
                                
                            <td rowSpan={2}>Click + to Add</td>
                        </tr>
                        <tr>
                         
                            <td>Period</td>
                            <td>Number</td>
                            <td>Period</td>
                            <td>Number</td>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="level"
                                    value={this.state.level}
                                    onChange={this.onChange}
                                    placeholder="Level of Guidance"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="number"
                                    value={this.state.number}
                                    onChange={this.onChange}
                                    placeholder="Number Enrolled"
                                />
                            </td>
                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="th_period"
                                    value={this.state.th_period}
                                    onChange={this.onChange}
                                    
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="th_number"
                                    value={this.state.th_number}
                                    onChange={this.onChange}
                                    
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="d_period"
                                    value={this.state.d_period}
                                    onChange={this.onChange}
                                    
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="d_number"
                                    value={this.state.d_number}
                                    onChange={this.onChange}
                                    
                                />
                            </td>


                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="score"
                                    value={this.state.score}
                                    onChange={this.onChange}
                                    placeholder="Score"
                                />
                            </td>
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.research_guidance.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.level}</td>
                                <td>{el.number}</td>
                                <td>{el.th_period}</td>
                                <td>{el.th_number}</td>
                                <td>{el.d_period}</td>
                                <td>{el.d_number}</td>
                                <td>{el.score}</td>
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                         <tr><td colSpan={8}>&nbsp;</td></tr>
                        {this.state.research_guidance.length>0 && <tr>
                            <td colSpan={6} align="right">Total</td>
                            <td colSpan={2} align="left">{this.state.total}</td>
                            </tr>}
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(ResearchGuidance)
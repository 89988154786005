import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import AuthService from '../auth_components/js/AuthService';
import ProfileLanding from '../Components/UploadProfile/ProfileLanding';

class ProfileUpdateContainer extends Component {
    constructor(props) {
        super(props);

        let Auth = new AuthService;

        let user = Auth.getUser();
        let username = user.email;
        let name = user.name;

        this.state = {
            username: username,
            apikey: user.apikey,
            name: name,
            user: user
        }

    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} username={this.state.username} apikey={this.state.apikey} />
                <div className="main-body-no-padding">
                    <ProfileLanding
                        history={this.props.history}
                        username={this.state.username}
                        name={this.state.name}
                        user={this.state.user}
                        apikey={this.state.apikey} />
                </div>
            </div>
        )
    }
}

export default withAuth(ProfileUpdateContainer);
import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class TeachingLearning extends Component {
    state = {
        course: '',
        level: '',
        mode: '',
        hour:'',
        percentage:'',
       teaching_learning: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            teaching_learning:this.props.teaching_learning,
            
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                teaching_learning:this.props.teaching_learning,
              

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            course: this.state.course,
            level: this.state.level,
            mode: this.state.mode,
            hour: this.state.hour,
            percentage: this.state.percentage,
            
        }

        let teaching_learning = this.state.teaching_learning;
        teaching_learning.push(d);
        this.setState({
            teaching_learning
        }, () => {
                this.props.setTeachingLearning(teaching_learning);
                this.setState({
                    course: '',
                    level: '',
                    mode: '',
                    hour:'',
                    percentage:'',
                })
        })
    }

    onDelete = (index) => {
        let teaching_learning = this.state.teaching_learning;
        teaching_learning.splice(index,1);
        this.props.setTeachingLearning(teaching_learning);
        this.setState({
            teaching_learning
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr >
                            <td >Course</td>
                            <td >Level</td>
                           
                            <td >Mode of teaching</td>
                                        
                           
                            <td >Hours per week alloted</td>
                            <td>% of classes taken as per documented record</td>
                                    
                                    
                            <td >Click + to Add</td>
                        </tr>
                       
                    </thead>
                    <tbody>
                        <tr>
                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="course"
                                    value={this.state.course}
                                    onChange={this.onChange}
                                    placeholder="Course"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="level"
                                    value={this.state.level}
                                    onChange={this.onChange}
                                    placeholder="Level"
                                />
                            </td>
                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="mode"
                                    value={this.state.mode}
                                    onChange={this.onChange}
                                    placeholder="Mode of teaching"
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="hour"
                                    value={this.state.hour}
                                    onChange={this.onChange}
                                    placeholder="Hours per week alloted"
                                />
                            </td>

                            <td >
                                <input
                                    className="form-control"
                                    type="text"
                                    name="percentage"
                                    value={this.state.percentage}
                                    onChange={this.onChange}
                                    placeholder="% of classes taken as per documented record"
                                />
                            </td>

                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.teaching_learning.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.course}</td>
                                <td>{el.level}</td>
                                <td>{el.mode}</td>
                                <td>{el.hour}</td>
                                <td>{el.percentage}</td>
                                
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                         
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(TeachingLearning)
import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class BooksPublished extends Component {
    state = {
        title: '',
        type: '',
        publisher_issn: '',
        peer:'',
        no_co_au:'',
        main_au:'',
        institution:'',
        api_score:'',
        year:'',
        books_published: []
    }

    componentDidMount(){
        //console.log(this.props)
        this.setState({
            books_published:this.props.books_published,
        })
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                books_published:this.props.books_published,

            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        let d = {
            title: this.state.title,
            type: this.state.type,
            publisher_issn: this.state.publisher_issn,
            peer: this.state.peer,
            no_co_au: this.state.no_co_au,
            main_au: this.state.main_au,
            institution: this.state.institution,
            api_score: this.state.api_score,
            year: this.state.year,
            
        }

        let books_published = this.state.books_published;
        books_published.push(d);
        this.setState({
            books_published
        }, () => {
                this.props.setBooksPublished(books_published);
                this.setState({
                    title: '',
                    type: '',
                    publisher_issn: '',
                    peer:'',
                    no_co_au:'',
                    main_au:'',
                    institution:'',
                    api_score:'',
                    year:'',
                })
        })
    }

    onDelete = (index) => {
        let books_published = this.state.books_published;
        books_published.splice(index,1);
        this.props.setBooksPublished(books_published);
        this.setState({
            books_published
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td width="20%">Name of Book
and year published</td>
                            <td width="10%">Type of Book &
                                    Authorship</td>
                           
                            <td width="20%">Publisher &
                                        ISSN/ ISBN No.</td>
                                        <td>Whether Peer Reviewed</td>
                           
                            <td>No. of 
                                    Co-author</td>
                                    <td>Whether you are the main author</td>
                                    <td>Whether at the time of publication Affiliating Institution was same
Yes/ No</td>
                                    <td>API Score</td>
                                    <td>Year</td>
                            <td>Click + to Add</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="20%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="title"
                                    value={this.state.title}
                                    onChange={this.onChange}
                                    placeholder="Name of Book
                                    and year published"
                                />
                            </td>
                            <td width="10%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="type"
                                    value={this.state.type}
                                    onChange={this.onChange}
                                    placeholder="Type of Book &
                                    Authorship"
                                />
                            </td>
                            <td width="20%">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="publisher_issn"
                                    value={this.state.publisher_issn}
                                    onChange={this.onChange}
                                    placeholder="Publisher &
                                    ISSN/ ISBN No."
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="peer"
                                    value={this.state.peer}
                                    onChange={this.onChange}
                                    placeholder="Whether Peer Reviewed"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="no_co_au"
                                    value={this.state.no_co_au}
                                    onChange={this.onChange}
                                    placeholder="No. of 
                                    Co-author"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="main_au"
                                    value={this.state.main_au}
                                    onChange={this.onChange}
                                    placeholder="Whether you are the main author"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="institution"
                                    value={this.state.institution}
                                    onChange={this.onChange}
                                    placeholder="Yes/No"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="api_score"
                                    value={this.state.api_score}
                                    onChange={this.onChange}
                                    placeholder="API Score"
                                />
                            </td>

                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="year"
                                    value={this.state.year}
                                    onChange={this.onChange}
                                    placeholder="Year"
                                />
                            </td>
                            
                           
                            <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.books_published.map((el, index) =>

                            <tr key={index}>
                                <td>{index+1}. {el.title}</td>
                                <td>{el.type}</td>
                                <td>{el.publisher_issn}</td>
                                <td>{el.peer}</td>
                                <td>{el.no_co_au}</td>
                                <td>{el.main_au}</td>
                                <td>{el.institution}</td>
                                <td>{el.api_score}</td>
                                <td>{el.year}</td>
                                
                                <td><Delete onClick={this.onDelete.bind(this,index)} /></td>
                            </tr>

                        )}
                       
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(BooksPublished)
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { withStyles } from "@material-ui/core/styles";

import { Provider } from "react-redux";
import store from "./redux/store/index";
import { getCourses, getSessions, getMydetails, getMyLeaveBalance, getMyDepartmentSubjects } from './redux/actions';
import { BrowserRouter } from 'react-router-dom';

const styles = theme => ({
  "@global": {
    html: {
      fontSize: 13,
      [theme.breakpoints.up("sm")]: {
        fontSize: 13
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 13
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 14
      }
    }
  },
  typography: {
    fontSize: 12
  }
});


// initialise value
store.dispatch(getCourses());
store.dispatch(getSessions());
// store.dispatch(getMydetails());
store.dispatch(getMyLeaveBalance());
store.dispatch(getMyDepartmentSubjects());

const AppStyled = withStyles(styles)(App);
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppStyled />
    </Provider>
  </BrowserRouter>, document.getElementById('root'));
registerServiceWorker();

import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';
import PreviousExamination from './PreviousExamination';

import { blue } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileUpload from './FileUpload';

import allStates from './../../../Helper/IndianStates.json'
import { GetData } from '../../../api/service';
import PreviousAppointment from './PreviousAppointment';
import PostAppointment from './PostAppointment';
import OtherCourseAttended from './OtherCourseAttended';
import Activities from './Activities';
import Journals from './Journals';
import Articles from './Articles';
import FullPapers from './FullPapers';
import BooksPublished from './BooksPublished';
import OnGoingProjects from './OnGoingProjects';
import CompletedProjects from './CompletedProjects';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})



class FormFour extends Component {

    state = {
        articles:[],
        full_papers:[],
        books_published:[],
        on_going_projects:[],
        completed_projects:[],
        phone: this.props.user.phone,
        email: this.props.user.email,
        step:3,
       step4_remarks:''
    }

    setArticles = (articles) =>{
        console.log(articles)
        this.setState({
            articles
        })
    }

    setFullPapers = (full_papers) =>{
        //console.log(full_papers)
        this.setState({
            full_papers
        })
    }

    setBooksPublished = (books_published) =>{
        //console.log(books_published)
        this.setState({
            books_published
        })
    }
    setOnGoingProjects = (on_going_projects) =>{
        //console.log(on_going_projects)
        this.setState({
            on_going_projects
        })
    }

    setCompletedProjects = (completed_projects) =>{
        //console.log(completed_projects)
        this.setState({
            completed_projects
        })
    }

    componentDidMount() {


        let data = this.props.data;
        //console.log(data)
       
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
            })
        }

    }

  


    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0],

                })
            }
        }

    }

    

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

       
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.setData(this.state);
       

    }

   
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div style={{ borderLeft: 'solid 5px #1715DA',paddingLeft:'10px' }}>
                    <Typography
                        variant="h5"
                    >
                        Application Form
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>

                    </div>

                   

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>

                        <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                              CATEGORY III: RESEARCH, PUBLICATIONS AND ACADEMIC CONTRIBUTIONS 
                            </Typography>
                            <br />



                            <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            B) (i) Articles/Chapters published in Books
                            </Typography>
                                <Articles setArticles={this.setArticles} total={this.state.total} articles={this.state.articles} />
                                </Grid>

                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            B) (ii) Full Papers in Conference Proceedings
                            </Typography>
                                <FullPapers setFullPapers={this.setFullPapers}  full_papers={this.state.full_papers} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                            B) (iii) Books Published as Single or as Editor
                            </Typography>
                                <BooksPublished setBooksPublished={this.setBooksPublished}  books_published={this.state.books_published} />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                           (iv) Ongoing and completed Research Project and Consultancies 
                           <br />
                                    C)  (I & II) Ongoing Projects/Consultancies
                            </Typography>
                                <OnGoingProjects setOnGoingProjects={this.setOnGoingProjects}  on_going_projects={this.state.on_going_projects} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                           (iv) Ongoing and completed Research Project and Consultancies 
                           <br />
                           C) (iii & iv) Completed Projects/Consultancies
                            </Typography>
                                <CompletedProjects setCompletedProjects={this.setCompletedProjects}  completed_projects={this.state.completed_projects} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                         
                          Remarks (if any)
                            </Typography>
                            <textarea
                                    className="form-control"
                                    type="text"
                                    name="step4_remarks"
                                    value={this.state.step4_remarks}
                                    onChange={this.onChange}
                                    placeholder="Remarks"
                                />
                                </Grid>

                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>
                        <Button

                                    type="button"
                                    onClick={this.props.back}
                                    >
                                    Previous
                                    </Button>
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormFour)
import React, { Component } from "react";
import {
  withStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
  Container,
} from "@material-ui/core";

import { blue, orange } from "@material-ui/core/colors";
import { GetData, PostData } from "../../api/service";
import PrintForm from "./PrintForm";
import ProfileLanding from "./ProfileLanding";
import ReactToPrint from "react-to-print";
import AlertDiv from "../AlertDiv";

import queryString from "query-string";

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});
const searchData = [
  {
    key: "activities",
    label:
      "CO-CURRICULAR, EXTENSION, PROFESSIONAL DEVELOPMENT RELATED ACTIVITIES",
  },
  {
    key: "other_course_attended",
    label: "Orientation/ Refresher Course",
  },
  {
    key: "journals",
    label: "Published Papers in Journals",
  },
  {
    key: "journal_paper",
    label: "Published Paper in Journals notified on UGC website",
  },
  {
    key: "articles",
    label: "Articles/Chapters published in Books",
  },
  {
    key: "full_papers",
    label: "Full Papers in Conference Proceedings",
  },
  {
    key: "books_published",
    label: "Books Published as Single or as Editor",
  },
  {
    key: "articles,full_papers,books_published",
    label:
      "Articles/Chapters published in Books + Full Papers in Conference Proceedings + Books Published as Single or as Editor",
  },
  {
    key: "on_going_projects",
    label: "Ongoing Projects/Consultancies",
  },
  {
    key: "completed_projects",
    label: "Completed Projects/Consultancies",
  },
  {
    key: "research_guidance",
    label: "Research Guidance",
  },
  {
    key: "training_courses",
    label:
      "Training Courses, Teaching-Learning-Evaluation Technology Programmes, Faculty Development Programmes",
  },
  {
    key: "papers_presented",
    label: "Papers presented in Conferences, Seminars, Workshop, Symposia",
  },
  {
    key: "invited_lectures",
    label:
      "Invited Lectures and Chairmanships at national or international conference/seminar",
  },
  {
    key: "teaching_learning",
    label: "Lectures, Seminars, Tutorials, Practical, Contact Hours ",
  },
  {
    key: "teaching_learning_score",
    label:
      "Teaching Learning Score (Lecture(L), Seminar(S), Tutorial(T), Practical(P), Contact Hours(C))",
  },
  {
    key: "reading_material",
    label:
      "Reading/Instructional materials consulted and additional knowledge resources provided to students",
  },
  {
    key: "participation_innovation",
    label:
      "Use of participatory and innovative Teaching-Learning Methodologies/Audio-visual teaching aid, Updating of subject Content, Course Improvement",
  },
  {
    key: "examination_duty",
    label: "Examination Duties Assigned and Performed",
  },
];

class ApiReport extends Component {
  state = {
    email: "",
    search: "",
    data: [],
    isLoaded: false,
    edit: false,
    params: queryString.parse(this.props.history.location.search),
    years: [],
    yearString: ""
  };

  componentDidMount() {
    this.getInitialData();

    
  }

  getInitialData = () => {
    

    if (this.props.public == true) {
      //fetch data
    let yy = queryString.parse(this.props.history.location.search);
    let years = yy.years;

    // check if empty
    if (years) {
      let yArray = years.split(",");

      this.setState({
        years: yArray,
      });
    }
      PostData(`/getemployeeforprofilesearch-public`, {
        search: this.state.params.key,
      }).then((resp) => {
        if (Array.isArray(resp) && resp.length > 0) {
          this.setState({
            data: resp,
            search: this.state.params.key,
            isLoaded: true,
          });
        } else {
          this.setState({
            isLoaded: true,
            search: "",
            data: [],
          });
        }
      });
    } else {


     
      let years = this.state.yearString;

      // check if empty
      if (years && years != "") {
        let yArray = years.split(",");

        this.setState({
          years: yArray,
        });
      }

      if (this.state.email !== "") {
        GetData(`/${this.state.email}/getemployeeforprofile`).then((resp) => {
          if (Array.isArray(resp) && resp.length > 0) {
            this.setState({
              data: resp,
              isLoaded: true,
            });
          } else {
            this.setState({
              isLoaded: true,
              data: [],
            });
          }
        });
      } else if (this.state.search !== "") {
        GetData(`/${this.state.search}/getemployeeforprofilesearch`).then(
          (resp) => {
            //console.log(resp)
            if (Array.isArray(resp) && resp.length > 0) {
              this.setState({
                data: resp,
                isLoaded: true,
              });
            } else {
              this.setState({
                isLoaded: true,
                data: [],
              });
            }
          }
        );
      } else if (this.state.search === "" && this.state.email === "") {
        GetData(`/getemployee`).then((resp) => {
          //console.log(resp)
          if (Array.isArray(resp) && resp.length > 0) {
            this.setState({
              data: resp,
              isLoaded: true,
            });
          } else {
            this.setState({
              isLoaded: true,
              data: [],
            });
          }
        });
      }
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        isLoaded: false,
        data: [],
      },
      () => {
        this.getInitialData();
      }
    );
  };

  render() {
    let { classes } = this.props;
    return (
      <div>
        {this.props.public == false && (
          <form onSubmit={this.onSubmit}>
            <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  style={{
                    color: blue[700],
                  }}
                >
                  Enter the email of the employee /select type of data required
                  then click search button to get the data.
                </Typography>
                <br />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Email
                    </Typography>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={(e) =>
                        this.setState({
                          email: e.target.value,
                          //search: "",
                          isLoaded: false,
                          data: [],
                        })
                      }
                      placeholder="Type email here"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Type of data required
                    </Typography>
                    <select
                      className="form-control"
                      type="text"
                      name="search"
                      value={this.state.search}
                      onChange={(e) =>
                        this.setState({
                          search: e.target.value,
                          //email: "",
                          isLoaded: false,
                          data: [],
                        })
                      }
                    >
                      <option value={``} disabled>
                        Select One
                      </option>
                      {searchData.map((el, index) => (
                        <option value={el.key}>{el.label}</option>
                      ))}
                    </select>
                  </Grid>


                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Years in comma-separated string (e.g. 2019,2020) (leave it blank to show all)
                    </Typography>
                    <input
                      className="form-control"
                      type="text"
                      name="yearString"
                      value={this.state.yearString}
                      onChange={(e) =>
                        this.setState({
                          yearString: e.target.value,
                          //search: "",
                          isLoaded: false,
                          data: [],
                        })
                      }
                      placeholder="(e.g. 2019,2020)"
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={classes.cardActions}>
                <Button
                  variant="contained"
                  className={classes.buttonSubmit}
                  type="submit"
                >
                  Search
                </Button>
              </CardActions>
            </Card>
          </form>
        )}

        {this.state.isLoaded && this.state.data.length == 0 && (
          <Card>
            <CardContent>No Data Found</CardContent>
          </Card>
        )}

        {this.state.isLoaded &&
        this.state.search !== "" &&
        this.state.data.length > 0 ? (
          <div style={{ backgroundColor: "white" }}>
            <Card>
              <CardContent>
                {this.props.public == false && (
                  <div style={{ textAlign: "center" }}>
                    <AlertDiv back={orange[100]} border={orange[400]}>
                      You can print this form or can save as pdf. Click on the
                      button below.
                    </AlertDiv>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          Print or Save
                        </Button>
                      )}
                      ref={(el) => (this.buttonRef = el)}
                      content={() => this.componentRef}
                      pageStyle={{ margin: "10px" }}
                      copyStyles={true}
                    />

                    {this.state.search != "" && (
                      <Container>
                        <br />
                        <div style={{ fontWeight: 700, textAlign: "left" }}>
                          Your public link for this report is:{" "}
                        </div>
                        <input
                          className="form-control"
                          value={`https://user.bhcollege.ac.in/api-report-public-link?key=${this.state.search}&years=${this.state.yearString}`}
                        />
                      </Container>
                    )}
                  </div>
                )}

                {this.props.public == true && (
                  <div style={{ textAlign: "center" }}>
                    <img src="/icon512.png" style={{ width: 100 }} />
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      B.H College, Howly, Barpeta
                    </Typography>
                    <Typography variant="body2"></Typography>
                    <Typography variant="body2" gutterBottom>
                      Assam
                    </Typography>
                    <Typography variant="h4" style={{ fontWeight: 700 }}>
                      <u>Faculty API Reports</u>
                    </Typography>
                    <br />

                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          Print or Save
                        </Button>
                      )}
                      ref={(el) => (this.buttonRef = el)}
                      content={() => this.componentRef}
                      pageStyle={{ margin: "10px" }}
                      copyStyles={true}
                    />
                  </div>
                )}
                <br />

                <div ref={(el) => (this.componentRef = el)}>
                  <Typography
                    variant="h5"
                    style={{
                      color: blue[700],
                      textAlign: "center",
                    }}
                  >
                    {
                      searchData.find((el) => el.key == this.state.search)
                        ?.label
                    }
                  </Typography>
                  <br />

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "activities"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.activities?.length > 0 && (
                                <>
                                  <table
                                    width="100%"
                                    className="table"
                                    key={index}
                                  >
                                    <thead>
                                      <tr>
                                        <td colSpan={3} align="center">
                                          <Typography
                                            style={{
                                              fontWeight: 700,
                                              fontSize: 15,
                                            }}
                                            variant="h5"
                                          >
                                            {el1.name}
                                          </Typography>

                                          <Typography style={{ fontSize: 12 }}>
                                            Department :: {el1.dept_name}
                                          </Typography>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td width="70%">Activity Name</td>
                                        <td>Period</td>
                                        <td>API claimed</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {el1.activities.map((el, idx) => (
                                        <tr key={idx}>
                                          <td>
                                            {idx + 1}. {el.activity_name}
                                          </td>
                                          <td>{el.period}</td>
                                          <td>{el.api_claimed}</td>
                                        </tr>
                                      ))}

                                      <tr>
                                        <td colSpan={2} align="right">
                                          Total
                                        </td>
                                        <td align="left">
                                          {el1.activity_total}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search ==
                        "articles,full_papers,books_published" && (
                        <>
                          {this.state.data.map((el1, index) => (
                            <div>
                              {el1.articles?.length > 0 && el1.articles.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={9} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 14 }}>
                                          Department :: {el1.dept_name}
                                          <br />
                                          <strong>
                                            Articles/Chapters published in Books
                                          </strong>
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="30%">
                                        Title with Page Numbers
                                      </td>
                                      <td width="20%">
                                        Book Title, editor & publisher and Year
                                      </td>

                                      <td>ISSN/ ISBN No.</td>
                                      <td>
                                        Whether Peer Reviewed Impact Factor, if
                                        any{" "}
                                      </td>
                                      <td>No. of Co-author</td>
                                      <td>Whether you are the main author</td>
                                      <td>
                                        Whether at the time of publication
                                        Affiliating Institution was same Yes/ No
                                      </td>
                                      <td>API Score</td>
                                      <td>Year</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.articles
                                      .filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      })
                                      .map((el, idx) => (
                                        <tr key={idx}>
                                          <td>
                                            {idx + 1}. {el.title}
                                          </td>
                                          <td>{el.publisher}</td>
                                          <td>{el.issn}</td>
                                          <td>{el.impact_factor}</td>
                                          <td>{el.no_co_au}</td>
                                          <td>{el.main_au}</td>
                                          <td>{el.institution}</td>
                                          <td>{el.api_score}</td>
                                          <td>{el.year}</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              )}

                              {el1.full_papers?.length > 0 && el1.full_papers?.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={9} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 14 }}>
                                          Department :: {el1.dept_name}
                                          <br />
                                          <strong>
                                            Full Papers in Conference
                                            Proceedings
                                          </strong>
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        Title with Page Numbers
                                      </td>
                                      <td width="40%">
                                        Title, editor & publisher and Year
                                      </td>

                                      <td>ISSN/ ISBN No.</td>

                                      <td>No. of Co-author</td>
                                      <td>Whether you are the main author</td>
                                      <td>
                                        Whether at the time of publication
                                        Affiliating Institution was same Yes/ No
                                      </td>
                                      <td>API Score</td>
                                      <td>Year</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.full_papers.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.details}</td>
                                        <td>{el.issn}</td>
                                        <td>{el.no_co_au}</td>
                                        <td>{el.main_au}</td>
                                        <td>{el.institution}</td>
                                        <td>{el.api_score}</td>
                                        <td>{el.year}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                              {el1.books_published?.length > 0 && el1.books_published.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={10} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 14 }}>
                                          Department :: {el1.dept_name}
                                          <br />
                                          <strong>
                                            Books Published as Single or as
                                            Editor
                                          </strong>
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        Name of Book and year published
                                      </td>
                                      <td width="10%">
                                        Type of Book & Authorship
                                      </td>

                                      <td width="20%">
                                        Publisher & ISSN/ ISBN No.
                                      </td>
                                      <td>Whether Peer Reviewed</td>

                                      <td>No. of Co-author</td>
                                      <td>Whether you are the main author</td>
                                      <td width="10%">
                                        Whether at the time of publication
                                        Affiliating Institution was same Yes/ No
                                      </td>
                                      <td>API Score</td>
                                      <td>Year</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.books_published.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.type}</td>
                                        <td>{el.publisher_issn}</td>
                                        <td>{el.peer}</td>
                                        <td>{el.no_co_au}</td>
                                        <td>{el.main_au}</td>
                                        <td>{el.institution}</td>
                                        <td>{el.api_score}</td>
                                        <td>{el.year}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "journals"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.journals?.length > 0 && el1.journals?.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="30%">
                                        Title with Page Numbers
                                      </td>
                                      <td width="20%">Journal</td>

                                      <td>ISSN/ ISBN No.</td>
                                      <td>
                                        Whether Peer Reviewed Impact Factor, if
                                        any{" "}
                                      </td>
                                      <td>No. of Co-author</td>
                                      <td>Whether you are the main author</td>
                                      <td>API Score</td>
                                      <td>Year</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.journals.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.journal}</td>
                                        <td>{el.issn}</td>
                                        <td>{el.impact_factor}</td>
                                        <td>{el.no_co_au}</td>
                                        <td>{el.main_au}</td>
                                        <td>{el.api_score}</td>
                                        <td>{el.year}</td>
                                      </tr>
                                    ))}

                                    <tr>
                                      <td colSpan={6} align="right">
                                        Total
                                      </td>
                                      <td align="left">{el1.journal_total}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "other_course_attended"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.other_course_attended?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={4} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Name of Course</td>
                                      <td>Place</td>
                                      <td>Duration</td>

                                      <td>Sponsoring Agency</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.other_course_attended.map(
                                      (el, idx) => (
                                        <tr key={idx}>
                                          <td>{el.course}</td>
                                          <td>{el.place}</td>
                                          <td>{el.duration}</td>
                                          <td>{el.sponsor}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "articles"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.articles?.length > 0 && el1.articles?.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="30%">
                                        Title with Page Numbers
                                      </td>
                                      <td width="20%">
                                        Book Title, editor & publisher and Year
                                      </td>

                                      <td>ISSN/ ISBN No.</td>
                                      <td>
                                        Whether Peer Reviewed Impact Factor, if
                                        any{" "}
                                      </td>
                                      <td>No. of Co-author</td>
                                      <td>Whether you are the main author</td>
                                      <td>
                                        Whether at the time of publication
                                        Affiliating Institution was same Yes/ No
                                      </td>
                                      <td>API Score</td>
                                      <td>Year</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.articles.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.publisher}</td>
                                        <td>{el.issn}</td>
                                        <td>{el.impact_factor}</td>
                                        <td>{el.no_co_au}</td>
                                        <td>{el.main_au}</td>
                                        <td>{el.institution}</td>
                                        <td>{el.api_score}</td>
                                        <td>{el.year}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "full_papers"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.full_papers?.length > 0 && el1.full_papers?.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        Title with Page Numbers
                                      </td>
                                      <td width="40%">
                                        Title, editor & publisher and Year
                                      </td>

                                      <td>ISSN/ ISBN No.</td>

                                      <td>No. of Co-author</td>
                                      <td>Whether you are the main author</td>
                                      <td>
                                        Whether at the time of publication
                                        Affiliating Institution was same Yes/ No
                                      </td>
                                      <td>API Score</td>
                                      <td>Year</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.full_papers.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.details}</td>
                                        <td>{el.issn}</td>
                                        <td>{el.no_co_au}</td>
                                        <td>{el.main_au}</td>
                                        <td>{el.institution}</td>
                                        <td>{el.api_score}</td>
                                        <td>{el.year}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "books_published"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.books_published?.length > 0 && el1.books_published?.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={9} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        Name of Book and year published
                                      </td>
                                      <td width="10%">
                                        Type of Book & Authorship
                                      </td>

                                      <td width="20%">
                                        Publisher & ISSN/ ISBN No.
                                      </td>
                                      <td>Whether Peer Reviewed</td>

                                      <td>No. of Co-author</td>
                                      <td>Whether you are the main author</td>
                                      <td width="10%">
                                        Whether at the time of publication
                                        Affiliating Institution was same Yes/ No
                                      </td>
                                      <td>API Score</td>
                                      <td>Year</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.books_published.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.type}</td>
                                        <td>{el.publisher_issn}</td>
                                        <td>{el.peer}</td>
                                        <td>{el.no_co_au}</td>
                                        <td>{el.main_au}</td>
                                        <td>{el.institution}</td>
                                        <td>{el.api_score}</td>
                                        <td>{el.year}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "on_going_projects"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.on_going_projects?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50%">Title</td>
                                      <td width="20%">Agency</td>

                                      <td>Period</td>
                                      <td>
                                        Grant/ Amount Mobilized (Rs. Lakh)
                                      </td>

                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.on_going_projects.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.agency}</td>
                                        <td>{el.period}</td>
                                        <td>{el.grant}</td>

                                        <td>{el.api_score}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "completed_projects"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.completed_projects?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="30%">Title</td>
                                      <td width="20%">Agency</td>

                                      <td>Period</td>
                                      <td>
                                        Grant/ Amount Mobilized (Rs. Lakh)
                                      </td>
                                      <td>
                                        Whether policy document/ patent as
                                        outcome
                                      </td>

                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.completed_projects.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>{el.title}</td>
                                        <td>{el.agency}</td>
                                        <td>{el.period}</td>
                                        <td>{el.grant}</td>
                                        <td>{el.document}</td>
                                        <td>{el.api_score}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "research_guidance"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.research_guidance?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td rowSpan={2}>Level of Guidance</td>
                                      <td rowSpan={2}>Number Enrolled</td>

                                      <td colSpan={2} align="left">
                                        Thesis/Dissertation submitted
                                      </td>

                                      <td colSpan={2} align="left">
                                        Degree Awarded
                                      </td>

                                      <td rowSpan={2}>Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.research_guidance.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.level}
                                        </td>
                                        <td>{el.number}</td>
                                        <td>{el.th_period}</td>
                                        <td>{el.th_number}</td>
                                        <td>{el.d_period}</td>
                                        <td>{el.d_number}</td>
                                        <td>{el.score}</td>
                                      </tr>
                                    ))}

                                    <tr>
                                      <td colSpan={5} align="right">
                                        Total
                                      </td>
                                      <td colSpan={2} align="left">
                                        {el1.research_total}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "training_courses"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.training_courses?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Programme</td>
                                      <td>Duration</td>

                                      <td>Organized By</td>

                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.training_courses.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.programme}
                                        </td>
                                        <td>{el.duration}</td>
                                        <td>{el.orgainised_by}</td>
                                        <td>{el.api_score}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "papers_presented"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.papers_presented?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        Title of the paper presented{" "}
                                      </td>
                                      <td width="20%">
                                        Title of Conference Seminar
                                      </td>

                                      <td>Organized By</td>
                                      <td>
                                        Whether international/ national/ state/
                                        regional/ college or university
                                      </td>

                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.papers_presented.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.paper}
                                        </td>
                                        <td>{el.conference}</td>
                                        <td>{el.orgainised_by}</td>
                                        <td>{el.type}</td>
                                        <td>{el.api_score}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "invited_lectures"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.invited_lectures?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        Title of Lecture/ Academic Session
                                      </td>
                                      <td width="20%">
                                        Title of Conference/ Seminar
                                      </td>

                                      <td>Organized By</td>
                                      <td>
                                        Whether international/ national/ state/
                                        regional/ college or university
                                      </td>

                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.invited_lectures.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.paper}
                                        </td>
                                        <td>{el.conference}</td>
                                        <td>{el.orgainised_by}</td>
                                        <td>{el.type}</td>
                                        <td>{el.api_score}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "journal_paper"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.journal_paper?.length > 0 && el1.journal_paper?.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td rowSpan={2}>Title of the paper</td>
                                      <td rowSpan={2}>Name of the journal</td>

                                      <td rowSpan={2}>Year of Publication.</td>
                                      <td rowSpan={2}>ISSN No. </td>
                                      <td colSpan={3}>
                                        Link to the recognition in UGC
                                        enlistment of the Journal/ Digital
                                        Object Identifier (doi) No.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Link to Website of the Journal</td>
                                      <td>
                                        Link to article/ paper/ abstract of the
                                        article
                                      </td>
                                      <td>
                                        Is it listed in UGC Carelist /Scopus/
                                        Web of Science / Other, Mention
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.journal_paper.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.title}
                                        </td>
                                        <td>{el.journal}</td>
                                        <td>{el.year}</td>
                                        <td>{el.issn}</td>
                                        <td>
                                          <a
                                            target="_blank"
                                            style={{ color: "blue" }}
                                            href={el.website_link}
                                          >
                                            View Document
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            target="_blank"
                                            style={{ color: "blue" }}
                                            href={el.article_link}
                                          >
                                            View Document
                                          </a>
                                        </td>
                                        <td>
                                          {el.listed_main} &nbsp; {el.listed}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "journal_paper_c"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.journal_paper?.filter(
                                (el) => el.listed_main == "UGC Carelist"
                              ).length > 0 && el1.journal_paper?.filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      }).length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td rowSpan={2}>Title of the paper</td>
                                      <td rowSpan={2}>Name of the journal</td>

                                      <td rowSpan={2}>Year of Publication.</td>
                                      <td rowSpan={2}>ISSN No. </td>
                                      <td colSpan={3}>
                                        Link to the recognition in UGC
                                        enlistment of the Journal/ Digital
                                        Object Identifier (doi) No.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Link to Website of the Journal</td>
                                      <td>
                                        Link to article/ paper/ abstract of the
                                        article
                                      </td>
                                      <td>
                                        Is it listed in UGC Carelist /Scopus/
                                        Web of Science / Other, Mention
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.journal_paper
                                      .filter(
                                        (el) => el.listed_main == "UGC Carelist"
                                      ).filter((item) => {
                                        const { year } = item;

                                        // If years array is empty, show all articles
                                        if (this.state.years.length === 0) {
                                          return true;
                                        }

                                        if(year == undefined || year  == "" || year == null){
                                          return true
                                        }

                                        // If years array is not empty, show articles with a matching year or an empty year
                                        return (
                                          this.state.years.includes(year)
                                        );
                                      })
                                      .map((el, idx) => (
                                        <tr key={idx}>
                                          <td>
                                            {idx + 1}. {el.title}
                                          </td>
                                          <td>{el.journal}</td>
                                          <td>{el.year}</td>
                                          <td>{el.issn}</td>
                                          <td>
                                            <a
                                              target="_blank"
                                              style={{ color: "blue" }}
                                              href={el.website_link}
                                            >
                                              View Document
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              style={{ color: "blue" }}
                                              href={el.article_link}
                                            >
                                              View Document
                                            </a>
                                          </td>
                                          <td>
                                            {el.listed_main} &nbsp; {el.listed}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "teaching_learning"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.teaching_learning?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Course</td>
                                      <td>Level</td>

                                      <td>Mode of teaching</td>

                                      <td>Hours per week alloted</td>
                                      <td>
                                        % of classes taken as per documented
                                        record
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.teaching_learning.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.course}
                                        </td>
                                        <td>{el.level}</td>
                                        <td>{el.mode}</td>
                                        <td>{el.hour}</td>
                                        <td>{el.percentage}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "teaching_learning_score"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.teaching_learning_score?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.teaching_learning_score.map(
                                      (el, idx) => (
                                        <tr key={idx}>
                                          <td>
                                            {idx + 1}. {el.class}
                                          </td>
                                          <td>{el.class_api}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "reading_material"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.reading_material?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Course/Paper</td>
                                      <td>Consulted</td>

                                      <td>Prescribed</td>

                                      <td>Additional resource provided</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.reading_material.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.course}
                                        </td>
                                        <td>{el.consulted}</td>
                                        <td>{el.prescribed}</td>
                                        <td>{el.resource}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "participation_innovation"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.participation_innovation?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Short Description</td>
                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.participation_innovation.map(
                                      (el, idx) => (
                                        <tr key={idx}>
                                          <td>
                                            {idx + 1}. {el.description}
                                          </td>
                                          <td>{el.api_score}</td>
                                        </tr>
                                      )
                                    )}

                                    <tr>
                                      <td>Total Score</td>
                                      <td>
                                        {el1.participation_innovation.reduce(
                                          (pre, curr) =>
                                            parseFloat(pre) +
                                            parseFloat(curr.api_score),
                                          0
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}

                  {this.state.isLoaded && this.state.search !== "" ? (
                    <>
                      {this.state.search === "examination_duty"
                        ? this.state.data.map(
                            (el1, index) =>
                              el1.examination_duty?.length > 0 && (
                                <table
                                  width="100%"
                                  className="table"
                                  key={index}
                                >
                                  <thead>
                                    <tr>
                                      <td colSpan={8} align="center">
                                        <Typography
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 15,
                                          }}
                                          variant="h5"
                                        >
                                          {el1.name}
                                        </Typography>

                                        <Typography style={{ fontSize: 12 }}>
                                          Department :: {el1.dept_name}
                                        </Typography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Type of Examination Duties</td>
                                      <td>Duties Assigned</td>
                                      <td>Extend to which carried out (%)</td>
                                      <td>API Score</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {el1.examination_duty.map((el, idx) => (
                                      <tr key={idx}>
                                        <td>
                                          {idx + 1}. {el.type}
                                        </td>
                                        <td>{el.duty}</td>
                                        <td>{el.extend}</td>
                                        <td>{el.api_score}</td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td colSpan={3}>Total Score</td>
                                      <td>
                                        {el1.examination_duty?.reduce(
                                          (pre, curr) =>
                                            parseFloat(pre) +
                                            parseFloat(curr.api_score),
                                          0
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              )
                          )
                        : null}
                    </>
                  ) : null}
                </div>
              </CardContent>
            </Card>
          </div>
        ) : null}

        {this.state.isLoaded && this.state.email !== "" ? (
          this.state.data.length ? (
            <>
              <br />
              {!this.state.edit && (
                <div className={classes.cardActions}>
                  <Button
                    onClick={() =>
                      this.setState({
                        edit: true,
                      })
                    }
                    style={{ marginRight: "2em" }}
                    color="secondary"
                    size="small"
                    variant="contained"
                    type="button"
                  >
                    EDIT
                  </Button>
                </div>
              )}
              <br />
              {!this.state.edit && (
                <PrintForm
                  history={this.props.history}
                  user={this.props.user}
                  data={this.state.data}
                />
              )}

              {this.state.edit && (
                <ProfileLanding
                  history={this.props.history}
                  user={this.state.data[0]}
                  data={this.state.data}
                />
              )}
            </>
          ) : (
            <Card>
              <CardContent>
                <Typography variant="h5">No data found.</Typography>
              </CardContent>
            </Card>
          )
        ) : (
          ""
        )}

        {this.state.isLoaded &&
          this.state.email === "" &&
          this.state.search === "" && (
            <Card>
              <CardContent>
                <div style={{ overflowX: "scroll" }}>
                  <table width="100%" className="table">
                    <thead>
                      <tr>
                        <td>
                          <b>#</b>
                        </td>
                        <td>
                          <b>Name</b>
                        </td>
                        <td>
                          <b>Department</b>
                        </td>
                        <td>
                          <b>Email</b>
                        </td>
                        <td>
                          <b>Phone</b>
                        </td>
                        <td>
                          <b>Link</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data?.map((el, index) => (
                        <tr key={index}>
                          <td style={{ fontSize: "20px" }}>{index + 1}</td>
                          <td style={{ fontSize: "20px" }}>{el.name}</td>
                          <td style={{ fontSize: "20px" }}>{el.dept_code}</td>
                          <td style={{ fontSize: "20px" }}>{el.email}</td>
                          <td style={{ fontSize: "20px" }}>{el.phone}</td>
                          <td style={{ fontSize: "20px" }}>
                            <a
                              href={`/apireportwithemail/${el.email}`}
                              target="_blank"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>
          )}
      </div>
    );
  }
}

export default withStyles(styles)(ApiReport);
